import { TableCell } from "@mui/material"
import Card from "@mui/material/Card"
import { styled } from "@mui/material/styles"
import { Link as DomLink } from "react-router-dom"

import { COLORS } from "assets/styles/colors"

export const Row = styled(Card)(({ theme }) => ({
  display: "flex",
  width: "100%",
  cursor: "pointer",
  boxSizing: "border-box",
  position: "relative",
  background: "white",
  margin: "8px 0",
  padding: "6px 12px",
  justifyContent: "space-between",
  alignItems: "center",
  "& > div": {
    padding: "6px",
  },
  "&:hover": {
    backgroundColor: COLORS.YELLOW_FADED,
  },
}))

export const CellDownload = styled(TableCell)(({ theme }) => ({
  cursor: "pointer",
  "&:hover > svg": {
    stroke: "black",
  },
}))

export const Link = styled(DomLink)`
  width: 100%;
`

export const ModalContainer = styled("div")`
  display: flex;
  flex-direction: column;
`
