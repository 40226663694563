import * as React from "react"

import styled from "@emotion/styled"

import Header from "components/header"

const Container = styled.main`
  min-height: 100vh;
  background-color: rgb(249, 249, 249);
`

interface Props {
  children: React.ReactNode
}

const EmptyLayout: React.FC<Props> = ({ children }) => (
  <Container>
    <Header />
    {children}
  </Container>
)

export default EmptyLayout
