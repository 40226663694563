import * as React from "react"

import { LocalShippingOutlined, Photo, Shop } from "@mui/icons-material"
import { styled } from "@mui/material"
import AppBar from "@mui/material/AppBar"
import Box from "@mui/material/Box"
import IconButton from "@mui/material/IconButton"
import Toolbar from "@mui/material/Toolbar"
import Typography from "@mui/material/Typography"
import { FormattedMessage, useIntl } from "react-intl"
import { Link } from "react-router-dom"

import logo from "assets/images/renault_logo.svg"

const Bar = styled(Toolbar)(() => ({
  justifyContent: "space-between",
}))

const icons = [
  {
    key: "header.orders",
    link: "route.orders",
    Icon: LocalShippingOutlined,
  },
  {
    key: "header.spotlight",
    link: "route.spotlights",
    Icon: Photo,
  },
  {
    key: "header.products",
    link: "route.products",
    Icon: Shop,
  },
]

export const HeaderLink = styled(Link)`
  display: flex;
  align-items: center;
`

function Header() {
  const intl = useIntl()

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="static" sx={{ boxShadow: "none", backgroundColor: "black", color: "white" }}>
        <Bar>
          <Box display="flex" alignItems="center">
            <HeaderLink to={"/"}>
              <img src={logo} height="30px" />
              <Typography variant="h6" pl={1}>
                <FormattedMessage id="header.title" />
              </Typography>
            </HeaderLink>
          </Box>
          <Box display="flex" alignItems="center">
            {icons.map((i) => (
              <Link key={i.key} to={intl.formatMessage({ id: i.link })}>
                <IconButton size="large" edge="start" color="inherit" sx={{ mr: 2 }}>
                  <i.Icon color="inherit" />
                  <Typography variant="h5" sx={{ ml: 1 }}>
                    {intl.formatMessage({ id: i.key })}
                  </Typography>
                </IconButton>
              </Link>
            ))}
          </Box>
        </Bar>
      </AppBar>
    </Box>
  )
}

export default Header
