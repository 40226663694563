import styled from "@emotion/styled"
import { Grid } from "@mui/material"
import { styled as muiStyled } from "@mui/material/styles"

import { fonts } from "assets/fonts/fonts"
import { COLORS } from "assets/styles/colors"

export const Image = styled.img`
  max-height: 60px;
`
export const Reference = styled.div`
  color: ${COLORS.GREY_DARK};
  display: flex;
  align-items: center;
  gap: 8px;
`
export const Price = styled.div`
  font-family: ${fonts.bold};
`
export const Icon = styled.div`
  font-size: 30px;
  cursor: pointer;
  color: ${COLORS.GREY_DARK};
  &:hover {
    color: ${COLORS.BLACK};
  }
`
export const Container = muiStyled(Grid)(() => ({
  "&:hover": {
    backgroundColor: COLORS.PRIMARY_LIGHT,
  },
}))
