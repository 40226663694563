import { createSlice } from "@reduxjs/toolkit"
import type { PayloadAction } from "@reduxjs/toolkit"

import { SpotlightsGET } from "types/api.types"

import { SpotlightsState } from "./spotlights.types"

const initialState: SpotlightsState = {
  published: [],
  unpublished: [],
  refresh: true,
  loading: false,
}

const spotlightsSlice = createSlice({
  name: "spotlights",
  initialState,
  reducers: {
    get: (state, action: PayloadAction<SpotlightsGET>) => {
      // immutable thanks to redux-toolkit
      const { published, unpublished } = action.payload
      state.published = published
      state.unpublished = unpublished
      state.refresh = false
      state.loading = false
    },
    refresh: (state) => {
      state.refresh = true
    },
    resetRefresh: (state) => {
      state.refresh = false
      state.loading = false
    },
    loading: (state) => {
      state.loading = true
    },
  },
})

export const { get, refresh, loading, resetRefresh } = spotlightsSlice.actions
export default spotlightsSlice.reducer
