import React, { useEffect, useRef, useState } from "react"

import styled from "@emotion/styled"
import { Box, Button, Card, CardContent, CardMedia, Container, Grid, TextField, Typography } from "@mui/material"
import dateFormat from "date-fns/format"
import CountUp from "react-countup"
import { DndProvider } from "react-dnd"
import { HTML5Backend } from "react-dnd-html5-backend"
import { FormattedMessage, useIntl } from "react-intl"
import {
  CartesianGrid,
  Cell,
  Legend,
  Line,
  LineChart,
  BarChart,
  Pie,
  PieChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
  Bar,
} from "recharts"

import api from "api"
import { COLORS } from "assets/styles/colors"
import RangeDateFilter from "components/filter/RangeDateFilter"
import TermSelectFilter from "components/filter/TermSelectFilter"
import AbsoluteLoader from "components/loader"
import { Card as CardProduct } from "components/product/Card"
import useDebounce from "hooks/useDebounce"
import { useSnackbarPromiseHandler } from "hooks/useSnackbar"
import { RootState, useReduxDispatch, useReduxSelector } from "store"
import actions from "store/actions"
import type { productData } from "types/common.types"
import { getSelectedTerm } from "utils/common.utils"
import { formatPrice } from "utils/format.utils"

import SettingsModal from "./SettingsModal"

const COLORS_PIE = [COLORS.PRIMARY, "#6b99f8", COLORS.BLUE_GREY, COLORS.GREEN]

export const Divider = styled.div`
  height: 2px;
  background-color: ${COLORS.GREY_DARK};
  width: 100%;
  opacity: 0.2;
`

const chartHeight = 300

const Home = () => {
  const _isMounted = useRef<boolean>(false)
  const [searchInput, setSearchInput] = useState("")
  const [isProductListOpen, setProductListOpen] = useState(false)
  const [showInvoicesSettings, setShowInvoicesSettings] = useState(false)
  const debounceInput = useDebounce(searchInput, 1000)

  const data = useReduxSelector((state: RootState) => state.kpi.global)
  const productData = useReduxSelector((state: RootState) => state.kpi.product)
  const selectedProductData = useReduxSelector((state: RootState) => state.kpi.selectedProduct)
  const invoicesSettings = useReduxSelector((state: RootState) => state.kpi.invoices)

  const dispatch = useReduxDispatch()
  const intl = useIntl()

  const [isProductLoading, loadProductData] = useSnackbarPromiseHandler(async () => {
    try {
      const { data: result } = await api.statistics.getByReference({
        q: debounceInput,
        dateMin: selectedProductData?.date?.selectedMin
          ? dateFormat(new Date(selectedProductData.date.selectedMin), "YYY-MM-dd")
          : undefined,
        dateMax: selectedProductData?.date?.selectedMax
          ? dateFormat(new Date(selectedProductData.date.selectedMax), "YYY-MM-dd")
          : undefined,
      })
      dispatch(actions.kpi.getProducts(result))
      setProductListOpen(true)
    } catch (e) {
      dispatch(actions.kpi.getProducts(undefined))
      dispatch(actions.kpi.resetRefresh())
    } finally {
      _isMounted.current = true
    }
  })

  const [isGlobalLoading, loadData] = useSnackbarPromiseHandler(async () => {
    try {
      const { data: result } = await api.statistics.get({
        section: getSelectedTerm(data?.section?.terms, undefined),
        dateMin: data?.date?.selectedMin ? dateFormat(new Date(data.date.selectedMin), "YYY-MM-dd") : undefined,
        dateMax: data?.date?.selectedMax ? dateFormat(new Date(data.date.selectedMax), "YYY-MM-dd") : undefined,
      })
      dispatch(actions.kpi.get(result))
    } catch (e) {
      dispatch(actions.kpi.resetRefresh())
    } finally {
      _isMounted.current = true
    }
  })

  const [isInvoicesLoading, getInvoicesConfiguration] = useSnackbarPromiseHandler(async () => {
    try {
      const { data: result } = await api.invoices.settings()
      dispatch(actions.kpi.getInvoices(result))
    } catch (e) {
      console.error("Error while fetching invoices configuration")
    }
  })

  const [isUpdateInvoicesLoading, updateInvoicesConfiguration] = useSnackbarPromiseHandler(async (form: string[]) => {
    try {
      await api.invoices.update(form)
      setShowInvoicesSettings(false)
    } catch (e) {
      console.error("Error while fetching invoices configuration")
    }
  }, "snackbar.success.invoices.update")

  useEffect(() => {
    if (showInvoicesSettings) {
      getInvoicesConfiguration()
    }
  }, [showInvoicesSettings])

  useEffect(() => {
    if (data.refresh) {
      loadData()
    }
  }, [data.refresh])

  useEffect(() => {
    if (productData.refresh) {
      loadProductData()
    }
  }, [productData.refresh])

  useEffect(() => {
    if (debounceInput) {
      loadProductData()
    }
  }, [debounceInput])

  useEffect(() => {
    dispatch(actions.kpi.refresh())
    getInvoicesConfiguration()
  }, [])

  const isLoading = isGlobalLoading || isProductLoading

  const { currentProductsCount, currentStockCount, ordersCount, salesRevenue, ordersCountsGraph, productsCountsGraph, date, section } =
    data || {}
  const ordersKey = intl.formatMessage({ id: "chart.orders" })
  const productsKey = intl.formatMessage({ id: "chart.products" })

  const ordersChart = ordersCountsGraph
    ? Object.entries(ordersCountsGraph)
        .map((e) => ({ name: e[0], [ordersKey]: e[1] }))
        .sort((g1, g2) => new Date(g1.name).getTime() - new Date(g2.name).getTime())
    : []
  const productsChart = productsCountsGraph
    ? Object.entries(productsCountsGraph)
        .map((e) => ({ name: e[0], [productsKey]: e[1] }))
        .sort((g1, g2) => new Date(g1.name).getTime() - new Date(g2.name).getTime())
    : []
  const pieChart = section
    ? section.terms
        .map(({ count, term }) => ({ name: intl.formatMessage({ id: `kpi.filter.section.${term}` }), value: count }))
        .sort((a, b) => a.name.localeCompare(b.name))
    : []

  const { image, name, reference, totalPrice, stock = 0, warningLevel = 0 } = selectedProductData.product || {}
  const {
    ordersCount: productOrdersCount = 0,
    salesRevenue: productSalesRevenue = 0,
    ordersCountsGraph: productOrdersCountsGraph,
    date: productDate,
  } = selectedProductData
  const productOrdersChart = productOrdersCountsGraph
    ? Object.entries(productOrdersCountsGraph)
        .map((e) => ({ name: e[0], [ordersKey]: e[1] }))
        .sort((g1, g2) => new Date(g1.name).getTime() - new Date(g2.name).getTime())
    : []

  const handleProductClick = (result: productData) => {
    dispatch(actions.kpi.getSelectedProduct(result))
    setProductListOpen(false)
  }

  return (
    <Container>
      {isLoading && <AbsoluteLoader />}
      {invoicesSettings && (
        <SettingsModal
          loading={isInvoicesLoading || isUpdateInvoicesLoading}
          open={showInvoicesSettings}
          onClose={() => setShowInvoicesSettings(false)}
          onConfirm={updateInvoicesConfiguration}
          invoices={invoicesSettings}
        />
      )}
      <Grid item container xs={12} pb={4}>
        <Grid item container xs={12} justifyContent="flex-end" pt={1}>
          <Button
            variant="contained"
            onClick={() => {
              setShowInvoicesSettings(true)
            }}
          >
            <FormattedMessage id="button.export.configure" />
          </Button>
        </Grid>
        <Grid item container xs={12} spacing={2}>
          <Grid item container xs={12}>
            <Box display="flex" flexDirection="column" pt={1} justifyContent="center">
              <Typography variant="h2">
                <FormattedMessage id="screen.kpi.title.1" />
              </Typography>
            </Box>
          </Grid>
          <Grid item container xs={12} justifyContent="flex-end">
            {section && (
              <Box width={chartHeight} p="6px">
                <TermSelectFilter
                  options={section.terms}
                  placeholderKey={"kpi.filter.section.placeholder"}
                  optionsPrefix={"kpi.filter.section"}
                  onClick={(value: string) => dispatch(actions.kpi.selectSection(value))}
                />
              </Box>
            )}
            {date && <RangeDateFilter filter={date} onChange={(value) => dispatch(actions.kpi.selectDate(value))} />}
          </Grid>

          <Grid item container xs={8}>
            <Card sx={{ width: "100%" }}>
              <CardContent>
                <Grid container>
                  <Grid item container xs={12}>
                    <Typography variant="h6">
                      <FormattedMessage id="screen.kpi.card.products" />
                    </Typography>
                  </Grid>
                  <Grid item container xs={8} height={150}>
                    {pieChart && pieChart.length > 0 && (
                      <ResponsiveContainer width="100%" height="100%">
                        <PieChart margin={{ top: -10 }}>
                          <Pie
                            data={pieChart}
                            dataKey="value"
                            nameKey="name"
                            cx="30%"
                            cy="50%"
                            fill="#8884d8"
                            labelLine
                            isAnimationActive={false}
                          >
                            {pieChart.map((entry, index: number) => (
                              <Cell key={`cell-${index}`} fill={COLORS_PIE[index % COLORS_PIE.length]} />
                            ))}
                          </Pie>
                          <Tooltip />
                          <Legend layout="vertical" align="left" verticalAlign="middle" />
                        </PieChart>
                      </ResponsiveContainer>
                    )}
                  </Grid>
                  <Grid item container xs={4}>
                    <Grid item container xs={12} pb={3}>
                      <Box display="flex" flexDirection="column" justifyContent="center">
                        <Typography variant="caption">
                          <FormattedMessage id="screen.kpi.currentProductsCount" />
                        </Typography>
                        <Typography variant="h5">
                          <CountUp duration={2.2 - (currentProductsCount < 100 ? 1 : 0)} separator=" " end={currentProductsCount} />
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid item container xs={12} pb={3}>
                      <Box display="flex" flexDirection="column" justifyContent="center">
                        <Typography variant="caption">
                          <FormattedMessage id="screen.kpi.currentStockCount" />
                        </Typography>
                        <Typography variant="h5">
                          <CountUp duration={2 - (currentStockCount < 100 ? 1 : 0)} separator=" " end={currentStockCount} />
                        </Typography>
                      </Box>
                    </Grid>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
          <Grid item container xs={4}>
            <Card sx={{ width: "100%" }}>
              <CardContent sx={{ height: "100%" }}>
                <Grid container sx={{ height: "100%" }}>
                  <Grid item container xs={12} justifyContent="center" alignItems="center">
                    <Grid item container justifyContent="center" alignItems="center">
                      <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center">
                        <Typography variant="caption">
                          <FormattedMessage id="screen.kpi.ordersCount" />
                        </Typography>
                        <Typography variant="h5">
                          <CountUp duration={1.5 - (ordersCount < 100 ? 1 : 0)} separator=" " end={ordersCount} />
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid item container justifyContent="center" alignItems="center">
                      <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center">
                        <Typography variant="caption">
                          <FormattedMessage id="screen.kpi.salesRevenue" />
                        </Typography>
                        <Typography variant="h5">
                          <CountUp duration={2.7 - (salesRevenue < 100 ? 1 : 0)} separator=" " end={salesRevenue} suffix=" €" />
                        </Typography>
                      </Box>
                    </Grid>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
          <Grid item container xs={6}>
            <Card sx={{ width: "100%" }}>
              <CardContent>
                <Grid item container xs={12} pb={1}>
                  <Typography variant="h6">
                    <FormattedMessage id="screen.kpi.card.totalProducts" />
                  </Typography>
                </Grid>
                <Grid item container xs={12}>
                  <ResponsiveContainer width="100%" height={chartHeight}>
                    <LineChart data={productsChart} margin={{ right: 40, top: 10 }}>
                      <CartesianGrid strokeDasharray="3 3" />
                      <XAxis dataKey="name" />
                      <YAxis />
                      <Tooltip />
                      <Legend />
                      <Line type="linear" dataKey={productsKey} stroke={COLORS.BLUE_GREY} strokeWidth={2} />
                    </LineChart>
                  </ResponsiveContainer>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
          <Grid item container xs={6}>
            <Card sx={{ width: "100%" }}>
              <CardContent>
                <Grid item container xs={12} pb={1}>
                  <Typography variant="h6">
                    <FormattedMessage id="screen.kpi.card.orders" />
                  </Typography>
                </Grid>
                <Grid item container xs={12}>
                  <ResponsiveContainer width="100%" height={chartHeight}>
                    <BarChart data={ordersChart} margin={{ right: 40, top: 10 }}>
                      <CartesianGrid strokeDasharray="3 3" />
                      <XAxis dataKey="name" />
                      <YAxis />
                      <Tooltip />
                      <Legend />
                      <Bar type="linear" dataKey={ordersKey} fill={COLORS.BLUE_GREY} />
                    </BarChart>
                  </ResponsiveContainer>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
        <Grid item container xs={12} pt={7}>
          <Divider />
        </Grid>
        <Grid item container xs={12} pt={2} spacing={2}>
          <Grid item container xs={12}>
            <Box display="flex" flexDirection="column" pt={2} justifyContent="center">
              <Typography variant="h2">
                <FormattedMessage id="screen.kpi.title.2" />
              </Typography>
            </Box>
          </Grid>
          <Grid item container xs={12} justifyContent="flex-end">
            <Grid item container xs p="6px">
              <TextField
                type="text"
                variant="outlined"
                label={intl.formatMessage({ id: "screen.kpi.reference.placeholder" })}
                fullWidth
                onChange={(e) => setSearchInput(e.target.value)}
              />
            </Grid>
            {productDate && <RangeDateFilter filter={productDate} onChange={(value) => dispatch(actions.kpi.selectProductDate(value))} />}
          </Grid>
          <Grid item container xs={12}>
            {isProductListOpen && (
              <DndProvider backend={HTML5Backend}>
                {productData.result?.map((p) => (
                    <Box key={p.product?.id} sx={{ width: "100%" }} pb={1} onClick={() => handleProductClick(p)}>
                      <CardProduct product={p.product} index={p.product?.order} isProductData />
                    </Box>
                  ))}
              </DndProvider>
            )}
          </Grid>
          <Grid item container xs={5}>
            <Card sx={{ width: "100%" }}>
              {image && <CardMedia component="img" height="150" image={image} sx={{ objectFit: "contain" }} alt="Paella dish" />}
              {selectedProductData?.product ? (
                <Grid container pl={2} pr={2} pb={2}>
                  <Grid item xs={12} pb={1}>
                    <Typography variant="h4">{name ?? "-"}</Typography>
                  </Grid>
                  <Grid item xs={6} container flexDirection="column" justifyContent="center" pb={1}>
                    <Typography variant="caption">
                      <FormattedMessage id="form.product.reference" />
                    </Typography>
                    <Typography variant="h6">{reference ?? "-"}</Typography>
                  </Grid>
                  <Grid item xs={6} container flexDirection="column" justifyContent="center" pb={1}>
                    <Typography variant="caption">
                      <FormattedMessage id="form.product.totalPrice" />
                    </Typography>
                    <Typography variant="h6">{formatPrice(intl, totalPrice)}</Typography>
                  </Grid>
                  <Grid item xs={6} container flexDirection="column" justifyContent="center" pb={1}>
                    <Typography variant="caption">
                      <FormattedMessage id="product.stock" />
                    </Typography>
                    <Typography variant="h6" sx={{ color: stock < warningLevel ? COLORS.ORANGE : COLORS.GREEN }}>
                      <CountUp duration={2.1} separator=" " end={stock} />
                    </Typography>
                  </Grid>
                  <Grid item xs={6} container flexDirection="column" justifyContent="center" pb={1}>
                    <Typography variant="caption">
                      <FormattedMessage id="form.product.warningLevel" />
                    </Typography>
                    <Typography variant="h6">
                      <CountUp duration={1.2} separator=" " end={warningLevel} />
                    </Typography>
                  </Grid>
                  <Grid item xs={6} container flexDirection="column" justifyContent="center" pb={1}>
                    <Typography variant="caption">
                      <FormattedMessage id="screen.kpi.ordersCount" />
                    </Typography>
                    <Typography variant="h6">
                      <CountUp duration={1.9} separator=" " end={productOrdersCount} />
                    </Typography>
                  </Grid>
                  <Grid item xs={6} container flexDirection="column" justifyContent="center" pb={1}>
                    <Typography variant="caption">
                      <FormattedMessage id="screen.kpi.salesRevenue" />
                    </Typography>
                    <Typography variant="h6">
                      <CountUp duration={1.4} separator=" " end={productSalesRevenue} suffix=" €" />
                    </Typography>
                  </Grid>
                </Grid>
              ) : (
                <Box display="flex" flex={1} height="100%" justifyContent="center" alignItems="center">
                  <Typography variant="h4">
                    <FormattedMessage id="common.noResult" />
                  </Typography>
                </Box>
              )}
            </Card>
          </Grid>
          <Grid item container xs={7}>
            <Card sx={{ width: "100%" }}>
              <CardContent>
                <Grid item container xs={12} pb={1}>
                  <Typography variant="h6">
                    <FormattedMessage id="screen.kpi.card.orders" />
                  </Typography>
                </Grid>
                <Grid item container xs={12}>
                  {productOrdersChart?.length > 0 ? (
                    <ResponsiveContainer width="100%" height={chartHeight}>
                      <BarChart data={productOrdersChart} margin={{ right: 40, top: 10 }}>
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="name" />
                        <YAxis />
                        <Tooltip />
                        <Legend />
                        <Bar type="linear" dataKey={ordersKey} fill={COLORS.BLUE_GREY} />
                      </BarChart>
                    </ResponsiveContainer>
                  ) : (
                    <Box display="flex" flex={1} height={chartHeight} justifyContent="center" alignItems="center">
                      <Typography variant="h4">
                        <FormattedMessage id="common.noData" />
                      </Typography>
                    </Box>
                  )}
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Grid>
    </Container>
  )
}

export default Home
