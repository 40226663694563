import { ADJUST_METHODS, CATEGORIES, PRODUCT_TYPES, SECTIONS, RVO_STORE_ORDER_STEP } from "types/enums.types"

const common = {
  [`autocomplete.${PRODUCT_TYPES.FLAG}`]: "Affiche",
  [`autocomplete.${PRODUCT_TYPES.FIFTY}`]: "Portes prix",
  [`autocomplete.${PRODUCT_TYPES.PRICE_HOLDERS}`]: "Lot/3",
  [`autocomplete.${PRODUCT_TYPES.T_FIVE}`]: "Lot/25",
  [`autocomplete.${PRODUCT_TYPES.THREE}`]: "Lot/50",
  "button.goBack": "Retour",
  "button.mea.add": "Ajouter une bannière",
  "button.export.configure": "Configurer les dates d'export des factures",
  "button.product.add": "Ajouter un produit",
  "button.validate": "Enregistrer",
  "button.product.duplicate": "Dupliquer le produit",
  "error.default": "Une erreur est survenue !",
  "common.noResult": "Aucun résultat",
  "common.noData": "Aucune donnée",
  [`form.mea.adjust.${ADJUST_METHODS.ADJUST}`]: "Ajuster",
  [`form.mea.adjust.${ADJUST_METHODS.FILL}`]: "Remplir",
  "form.mea.adjust": "Méthode d'ajustement",
  "form.mea.creationDate": "Créée le {date}",
  "form.mea.modificationDate": "Modifiée le {date}",
  "form.mea.endDate": "Date de dépublication",
  "form.mea.link": "🔗 Lien",
  "form.mea.published": "Publiée",
  "form.mea.startDate": "Date de publication",
  "form.mea.subtitle": "Sous-titre",
  "form.mea.title": "Titre",
  "form.product.name": "Nom",
  "form.product.description": "Description",
  "form.product.reference": "Référence",
  "form.product.stock": "Stock",
  "product.stock": "Stock actuel",
  "form.product.warningLevel": "Seuil d'alerte",
  "form.product.section": "Rubrique",
  "form.product.category": "Catégorie",
  "form.product.type": "Type de produit",
  "form.product.soldBy": "Vendu par",
  "form.product.totalPrice": "Prix de vente total (HT)",
  "form.product.unitPrice": "Prix de vente unitaire (HT)",
  "form.boolean.visible": "Visible ?",
  "chart.orders": "Commandes",
  "chart.products": "Nb. produits",
  "screen.kpi.title.1": "Suivi des commandes",
  "screen.kpi.title.2": "Suivi par produit",
  "screen.kpi.reference.placeholder": "Rechercher un produit par nom ou par référence",
  "screen.kpi.card.products": "KPI Produits",
  "screen.kpi.card.orders": "Évolution des commandes",
  "screen.kpi.card.totalProducts": "Évolution du nombre de produits",
  "screen.kpi.currentProductsCount": "Références en ligne",
  "screen.kpi.currentStockCount": "Stock total actuel",
  "screen.kpi.ordersCount": "Commandes passées sur la période",
  "screen.kpi.salesRevenue": "Chiffre d'affaire sur la période",
  "kpi.filter.section.placeholder": "Section",
  "kpi.filter.section.default": "Toutes",
  [`kpi.filter.section.${SECTIONS.GOODIES}`]: "Goodies",
  [`kpi.filter.section.${SECTIONS.POS_AND_PERMANENT_MATERIAL}`]: "PLV et matériel permanent",
  [`kpi.filter.section.${SECTIONS.POS_NATIONAL_COMMUNICATION}`]: "PLV et communication nationale",
  [`kpi.filter.section.${SECTIONS.ADDITIONAL_TOOLS_AND_MATERIALS}`]: "Outil et matériel complémentaire",
  "kpi.filter.date.max": "Date de fin",
  "kpi.filter.date.min": "Date de début",
  "header.products": "Produits",
  "header.spotlight": "MEA",
  "header.title": "RVO Store",
  "header.orders": "Commandes",
  "modal.delete.title": "Suppression",
  "modal.delete.mea.body": "Êtes-vous sûr de vouloir supprimer cette mise en avant ?",
  "modal.delete.product.body": "Êtes-vous sûr de vouloir supprimer ce produit ?",
  "modal.confirmation.true": "Confirmer",
  "modal.confirmation.false": "Annuler",
  "modal.invoices.title": "Export des factures",
  "modal.invoices.year": "Année",
  "settings.invoices.0": "Janvier",
  "settings.invoices.1": "Février",
  "settings.invoices.2": "Mars",
  "settings.invoices.3": "Avril",
  "settings.invoices.4": "Mai",
  "settings.invoices.5": "Juin",
  "settings.invoices.6": "Juillet",
  "settings.invoices.7": "Août",
  "settings.invoices.8": "Septembre",
  "settings.invoices.9": "Octobre",
  "settings.invoices.10": "Novembre",
  "settings.invoices.11": "Décembre",
  "product.reference": "Ref. {reference}",
  "product.soldBy": "Vendu par {soldBy}",
  "product.warningLevel": "Seuil: {warningLevel}",
  "screen.mea.link.tooltip.1": "Pour faire un lien absolu - lien externe ou lien complet - il faut commencer par http(s)://",
  "screen.mea.link.tooltip.2": "Sinon le lien sera relatif au site RVO",
  "screen.mea.adjustment.tooltip.1":
    "Ajuster : l'image s'adapte à la taille de son conteneur, pouvant faire apparaître des bordures horizontales ou verticales.",
  "screen.mea.adjustment.tooltip.2": "Remplir : l'image prend toute la place du conteneur, pouvant rogner les bords dépassant celui-ci.",
  "screen.mea.nbResults": "Mises en avant ({nbResults})",
  "screen.mea.order": "Mises en avant en ligne ({nbResults})",
  "screen.mea.title": "Ajouter une Mise en Avant",
  "screen.product.create": "Ajouter un produit",
  "screen.products.title": "Produits",
  "screen.product.visibility.off": "Rendre visible sur le site RVO",
  "screen.product.visibility.on": "Retirer du site RVO",
  "screen.product.edit": "Modifier le produit",
  "snackbar.success.invoices.update": "Dates d'export des factures mises à jour !",
  "snackbar.success.spotlight.create": "Bannière créée !",
  "snackbar.success.product.create": "Produit créé !",
  "snackbar.success.spotlight.edit": "Bannière modifiée !",
  "snackbar.success.product.edit": "Produit modifié !",
  "snackbar.success.spotlight.delete": "Bannière supprimée !",
  "snackbar.success.product.delete": "Produit supprimé !",
  "snackbar.success.product.copy": "Référence copiée !",
  "spotlight.startDate.error": "Date de publication non atteinte !",
  "spotlight.title": "Titre",
  "spotlight.link": "Lien",
  "spotlight.creationDate": "Date de création",
  "spotlight.modificationDate": "Date de modification",
  "spotlight.endDate.error": "Date de dépublication dépassée !",
  "order.reference.placeholder": "Rechercher par numéro de commande ou par addresse de facturation",
  "order.orderId": "Commande n°",
  "order.creationDate": "Date",
  "order.billingAddress": "Adresse de facturation",
  "order.numberItems": "Nb. de produits",
  "order.totalPrice": "Total",
  "order.title": "Commande n°{orderId} - {billingAddress}",
  [`rvoStore.stepper.${RVO_STORE_ORDER_STEP.TO_VALIDATE}`]: "Commande en attente de validation",
  [`stepper.${RVO_STORE_ORDER_STEP.PLACED}`]: "Commande passée",
  [`stepper.${RVO_STORE_ORDER_STEP.CANCELLED}`]: "Commande annulée",
  [`stepper.${RVO_STORE_ORDER_STEP.ERROR}`]: "Commande en erreur",
  [`stepper.${RVO_STORE_ORDER_STEP.IN_PROCESS}`]: "En cours de traitement",
  [`stepper.${RVO_STORE_ORDER_STEP.SHIPPED}`]: "Expédiée",
  [`stepper.${RVO_STORE_ORDER_STEP.RECEIVED}`]: "Réceptionnée",
  "stepper.trackingUrl": "Suivre la livraison",
  "stepper.question1": "Vous avez une question / réclamation ?",
  "stepper.question2": "Vous pouvez contacter la société Staci au\u00a001\u00a034\u00a040\u00a029\u00a090",
  "order.quantity": "Quantité : {quantity}",
  "product.price.ht": "HT",
  [`select.category.${CATEGORIES.FLAGS}`]: "Drapeaux",
  [`select.category.${CATEGORIES.STICKERS}`]: "Stickers",
  [`select.category.${CATEGORIES.POSTERS}`]: "Affiches",
  [`select.category.${CATEGORIES.PLATE_COVERS}`]: "Cache plaque",
  [`select.category.${CATEGORIES.PRICE_HOLDERS}`]: "Supports de prix",
  [`select.category.${CATEGORIES.GOODIES}`]: "Goodies",
  [`select.category.${CATEGORIES.OTHERS}`]: "Autres",
  [`select.section.${SECTIONS.GOODIES}`]: "Goodies",
  [`select.section.${SECTIONS.POS_AND_PERMANENT_MATERIAL}`]: "PLV et matériel permanent",
  [`select.section.${SECTIONS.POS_NATIONAL_COMMUNICATION}`]: "PLV et communication nationale",
  [`select.section.${SECTIONS.ADDITIONAL_TOOLS_AND_MATERIALS}`]: "Outil et matériel complémentaire",
  [`toggle.${SECTIONS.GOODIES}`]: "Goodies ({count})",
  [`toggle.${SECTIONS.POS_AND_PERMANENT_MATERIAL}`]: "PLV et matériel permanent ({count})",
  [`toggle.${SECTIONS.POS_NATIONAL_COMMUNICATION}`]: "PLV et communication nationale ({count})",
  [`toggle.${SECTIONS.ADDITIONAL_TOOLS_AND_MATERIALS}`]: "Outil et matériel complémentaire ({count})",
}

export default common
