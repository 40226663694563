import common from "./common.i18n"
import routes from "./routes.i18n"
import server from "./server.i18n"

const fr = {
  locale: "fr",
  messages: {
    ...common,
    ...routes,
    ...server,
  },
}

export default fr
