import React from "react"

import { Autocomplete, Checkbox, FormControl, InputLabel, MenuItem, Select, TextField } from "@mui/material"
import { Control, Controller as FormController, Path } from "react-hook-form"
import { FormattedMessage, useIntl } from "react-intl"

import { FormField } from "types/form.types"

interface Props<formType> {
  field: FormField<Path<formType>>
  control: Control<formType>
}

function Controller<formType>({ field, control }: Props<formType>) {
  const intl = useIntl()
  const sxTheme = { backgroundColor: "white" }
  const { key, type, disabled, required, values, autocomplete, inputProps } = field

  return (
    <FormController
      name={key}
      control={control}
      rules={{ required: !!required }}
      render={({ field: f }) => (
        <>
          {(type === "number" || type === "string") && (
            <TextField
              {...f}
              required={!!required}
              type={type}
              InputProps={inputProps}
              variant="outlined"
              label={intl.formatMessage({ id: `form.product.${key}` })}
              fullWidth
              disabled={!!disabled}
              sx={sxTheme}
            />
          )}
          {type === "multiline" && (
            <TextField
              {...f}
              required={!!required}
              type={type}
              variant="outlined"
              label={intl.formatMessage({ id: `form.product.${key}` })}
              fullWidth
              multiline
              minRows={4}
              disabled={!!disabled}
              sx={sxTheme}
            />
          )}
          {type === "boolean" && (
            <>
              <FormattedMessage id={`form.boolean.${f.name}`} />
              <Checkbox {...f} checked={!!f.value} />
            </>
          )}
          {type === "autocomplete" && (
            <Autocomplete
              freeSolo
              options={
                autocomplete ? Object.values(autocomplete).map((option) => intl.formatMessage({ id: `autocomplete.${option}` })) : []
              }
              inputValue={f.value as string}
              disabled={!!disabled}
              onChange={(e, value) => f.onChange(value ?? "")}
              onInputChange={(e, value) => f.onChange(value ?? "")}
              renderInput={(params) => (
                <TextField
                  {...params}
                  required={!!required}
                  type={type}
                  variant="outlined"
                  label={intl.formatMessage({ id: `form.product.${key}` })}
                  fullWidth
                  disabled={!!disabled}
                  sx={sxTheme}
                />
              )}
            />
          )}
          {type === "select" && (
            <FormControl fullWidth>
              <InputLabel id={key}>{intl.formatMessage({ id: `form.product.${key}` })}</InputLabel>
              <Select
                id={key}
                {...f}
                required={!!required}
                type={type}
                variant="outlined"
                label={intl.formatMessage({ id: `form.product.${key}` })}
                fullWidth
                sx={sxTheme}
              >
                {values &&
                  values.map((v) => (
                    <MenuItem key={v} value={v}>
                      <FormattedMessage id={`${type}.${key}.${v}`} />
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          )}
        </>
      )}
    />
  )
}

export default Controller
