import { createSlice } from "@reduxjs/toolkit"

import { LOCAL_STORAGE } from "utils/authentication-constants.utils"

import { AuthState } from "./auth.types"

const initialState: AuthState = {
  isAuthenticated: !!localStorage.getItem(LOCAL_STORAGE.API_TOKEN),
}

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    login: (state) => {
      state.isAuthenticated = true
    },
    reset: (state) => {
      state.isAuthenticated = false
    },
  },
})

export const { login, reset } = authSlice.actions
export default authSlice.reducer
