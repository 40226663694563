import React, { useEffect, useState } from "react"

import styled from "@emotion/styled"
import { Cancel, Upload } from "@mui/icons-material"
import { useDropzone } from "react-dropzone"

import { COLORS } from "assets/styles/colors"

const getColor = (props: any) => {
  if (props.error) {
    return COLORS.RED
  }
  if (props.isDragAccept) {
    return COLORS.GREY_DARK
  }
  if (props.isDragReject) {
    return COLORS.GREY_DARK
  }
  if (props.isDragActive) {
    return COLORS.GREY_DARK
  }
  if (props.anyFile) {
    return COLORS.PRIMARY
  }
  return COLORS.BLACK
}

type ContainerProps = {
  anyFile: boolean
}

const Container = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px;
  border-width: 2px;
  border-radius: 2px;
  border-color: ${(props) => getColor(props)};
  border-style: dashed;
  background-color: ${(props: ContainerProps) => (props.anyFile ? COLORS.PRIMARY_LIGHT : COLORS.WHITE)};
  color: ${COLORS.PRIMARY};
  outline: none;
  cursor: pointer;
  transition: border 0.24s ease-in-out;
`
const InputBody = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
`
const IconContainer = styled.div`
  display: flex;
  align-items: center;
  font-size: 50px;
  color: ${COLORS.BLACK};
`
const DropzoneContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  height: 100%;
`
const CancelContainer = styled.div`
  position: absolute;
  right: 6px;
  top: 6px;
  color: ${COLORS.GREY_DARK};
`

type ImageProps = {
  maxHeight?: number
}

const Image = styled.img`
  max-width: 100%;
  ${({ maxHeight }: ImageProps) => maxHeight && `max-height: ${maxHeight}px;`}
`

type Props = {
  name: string
  value?: string
  maxHeight?: number
  onChange: (acceptedFiles: Array<any> | null) => any
}

const DropZone = ({ name, onChange, value, maxHeight }: Props) => {
  const [acceptedFiles, setAcceptedFiles] = useState<Array<unknown>>([])
  const [url, setUrl] = useState<any>(value ?? null)

  const onDrop = (acceptedFiles: Array<unknown>) => {
    setAcceptedFiles(acceptedFiles)
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    setUrl(URL.createObjectURL(acceptedFiles[0]))
    onChange(acceptedFiles)
  }

  const { getRootProps, getInputProps, isDragActive, isDragAccept, isDragReject } = useDropzone({ onDrop })

  const anyFile = acceptedFiles.length > 0

  useEffect(() => {
    setUrl(value)
  }, [value])

  return (
    <DropzoneContainer>
      <Container {...getRootProps({ isDragActive, isDragAccept, isDragReject })} anyFile={anyFile}>
        <input {...getInputProps()} name={name} />
        <InputBody>
          <IconContainer>
            {url ? <Image maxHeight={maxHeight} alt="uploaded-image" src={url} /> : <Upload fontSize="inherit" color="inherit" />}
          </IconContainer>
        </InputBody>
        {anyFile && (
          <CancelContainer
            onClick={(e) => {
              e.stopPropagation()
              setAcceptedFiles([])
              setUrl(null)
              onChange(null)
            }}
          >
            <Cancel />
          </CancelContainer>
        )}
      </Container>
    </DropzoneContainer>
  )
}

export default DropZone
