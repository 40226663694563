import styled from "@emotion/styled"

import { fonts } from "assets/fonts/fonts"
import { COLORS } from "assets/styles/colors"

type ContainerProps = {
  warning: boolean
}

export const Container = styled.div`
  color: ${({ warning }: ContainerProps) => (warning ? COLORS.ORANGE : COLORS.GREEN)};
  font-size: 18px;
  border-radius: 6px;
`
export const Icon = styled.div`
  line-height: 1px;
  font-size: 34px;
  padding-right: 6px;
`
export const IconStock = styled.div`
  font-family: ${fonts.bold};
  font-size: 26px;
  line-height: 20px;
`
