import React from "react"

import styled from "@emotion/styled"
import { FormattedMessage } from "react-intl"

import { fonts } from "assets/fonts/fonts"
import { COLORS } from "assets/styles/colors"

interface Props {
  active: boolean
  title: string
  onClick?: () => unknown
  count?: number
}

interface ContainerProps {
  active: boolean
}

const Container = styled.div`
  font-family: ${fonts.bold};
  cursor: pointer;
  opacity: ${({ active }: ContainerProps) => (active ? 1 : 0.6)};
  ${({ active }: ContainerProps) => active && `border-bottom: 2px solid ${COLORS.PRIMARY};`}
`

const Toggle = ({ active, title, count, onClick }: Props) => {
  return (
    <Container active={active} onClick={onClick}>
      <FormattedMessage id={`toggle.${title}`} values={{ count }} />
    </Container>
  )
}

export default Toggle
