import type { PayloadAction } from "@reduxjs/toolkit"
import { createSlice } from "@reduxjs/toolkit"

import type { OrdersGET } from "types/api.types"

import type { OrderState } from "./orders.types"

const initialState: OrderState = {
  result: [],
  q: "",
  page: 1,
  size: 12,
  nbResults: 0,
}

const orderSlice = createSlice({
  name: "orders",
  initialState,
  reducers: {
    get: (state, action: PayloadAction<OrdersGET>) => {
      const { results, size, page, nbResults, q } = action.payload
      state.result = results
      state.q = q
      state.nbResults = nbResults
      state.size = size
      state.page = page
    },
  },
})

export const { get } = orderSlice.actions
export default orderSlice.reducer
