import React from "react"

import { Box, Grid } from "@mui/material"
import { FormattedMessage, useIntl } from "react-intl"

import { fonts } from "assets/fonts/fonts"
import * as S from "screens/products/Products.styles"
import type { OrderItem } from "types/common.types"
import { formatPrice } from "utils/format.utils"

interface Props {
  product: OrderItem
}

const Product: React.FC<Props> = ({ product }) => {
  const { name, totalPrice, image, description, quantity } = product
  const intl = useIntl()

  return (
    <S.Container>
      <S.ImgContainer>
        <S.Overlay className="overlay">{description}</S.Overlay>
        <Box display="flex" alignItems="center" justifyContent="center">
          <S.Image src={image} />
        </Box>
      </S.ImgContainer>
      <Grid container sx={{ height: "100%" }} p={1.5}>
        <Grid item xs={12}>
          <Box sx={{ fontFamily: fonts.bold }} pb={1}>
            {name}
          </Box>
          <FormattedMessage id="order.quantity" values={{ quantity }} />
        </Grid>
        <Grid item container xs={12} pt={1}>
          <Box display="flex">
            <S.Price>{formatPrice(intl, totalPrice)}</S.Price>
            <S.PriceTax>
              <FormattedMessage id="product.price.ht" />
            </S.PriceTax>
          </Box>
        </Grid>
      </Grid>
    </S.Container>
  )
}

export default Product
