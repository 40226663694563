import React from "react"

import { useSelector } from "react-redux"
import { BrowserRouter as Router } from "react-router-dom"

import * as ENV from "config/env"
import AppRouter from "routers/AppRouter"
import AuthenticationRouter from "routers/AuthenticationRouter"

import ScrollToTop from "./routers/ScrollToTop"
import { RootState } from "./store"

function App() {
  const isAuthenticated = useSelector((state: RootState) => state.auth.isAuthenticated)

  if (!window.location.pathname.includes(ENV.ROUTER_BASE_URL)) {
    window.history.replaceState("", "", ENV.ROUTER_BASE_URL + window.location.pathname)
  }

  return (
    <Router basename="/store">
      <ScrollToTop />
      {isAuthenticated ? <AppRouter /> : <AuthenticationRouter />}
    </Router>
  )
}

export default App
