import type { PayloadAction } from "@reduxjs/toolkit"
import { createSlice } from "@reduxjs/toolkit"

import { ProductsGET } from "types/api.types"
import { SECTIONS } from "types/enums.types"

import { ProductsState } from "./products.types"

const initialState: ProductsState = {
  products: [],
  product: undefined,
  section: SECTIONS.POS_AND_PERMANENT_MATERIAL,
  refresh: true,
  loading: false,
}

const productsSlice = createSlice({
  name: "products",
  initialState,
  reducers: {
    get: (state, action: PayloadAction<ProductsGET>) => {
      const { results, sectionsCounts } = action.payload
      state.products = results
      state.sectionsCounts = sectionsCounts
      state.refresh = false
      state.loading = false
    },
    resetRefresh: (state) => {
      state.loading = false
      state.refresh = false
    },
    refresh: (state) => {
      state.refresh = true
    },
    loading: (state) => {
      state.loading = true
    },
    selectSection: (state, action: PayloadAction<SECTIONS>) => {
      state.section = action.payload
      state.refresh = true
    },
    setProduct: (state, action: PayloadAction<ProductsState["product"]>) => {
      state.product = action.payload
    },
    resetProduct: () => initialState,
  },
})

export const { get, refresh, selectSection, loading, resetRefresh, setProduct, resetProduct } = productsSlice.actions
export default productsSlice.reducer
