import React from "react"

import { Route, Routes } from "react-router-dom"

import Layout from "layout/Layout"
import Authentication from "screens/authentication"

const AuthenticationRouter: React.FunctionComponent = () => {
  return (
    <Layout>
      <Routes>
        <Route path={"*"} element={<Authentication />} />
      </Routes>
    </Layout>
  )
}

export default AuthenticationRouter
