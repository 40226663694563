export default {
  spotlights: {
    get: "/spotlights",
    put: "/spotlights",
    order: "/spotlights/order",
    update: (id: string) => `/spotlights/${id}`,
    publish: (id: string) => `/spotlights/${id}/publish`,
    delete: (id: string) => `/spotlights/${id}`,
  },
  products: {
    get: "/products",
    put: "/products",
    order: "/products/order",
    update: (id: string) => `/products/${id}`,
    delete: (id: string) => `/products/${id}`,
  },
  statistics: {
    get: "/statistics",
    getProducts: "/products/statistics",
  },
  invoices: {
    settings: "/invoices/settings",
  },
  orders: {
    getOrders: "/orders",
    getOrder: (id: number) => `/orders/${id}`,
  },
}
