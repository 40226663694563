import React, { useEffect } from "react"

import styled from "@emotion/styled"
import CircularProgress from "@mui/material/CircularProgress"
import { useSnackbar } from "notistack"
import { useIntl } from "react-intl"
import { useDispatch } from "react-redux"
import { useNavigate, useSearchParams } from "react-router-dom"

import * as ENV from "config/env"
import actions from "store/actions"
import { AUTH_MODE, AUTH_TYPE, LOCAL_STORAGE } from "utils/authentication-constants.utils"
import { generateLoginUrl, getAccessToken, inIframe } from "utils/authentication.utils"
import { searchParamToObject } from "utils/common.utils"
import { snackbarServerErrorUnknown } from "utils/snackbar.utils"

const Container = styled.main`
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
  justify-content: center;
  font-size: calc(10px + 2vmin);
`

const USBAuthenticator = () => {
  const arca = true
  const d = useDispatch()
  const navigate = useNavigate()
  const intl = useIntl()
  const { enqueueSnackbar } = useSnackbar()

  const authenticateUser = () => d(actions.auth.login())

  const [searchParams] = useSearchParams()
  const parsed = searchParamToObject(searchParams)
  const { code, state, error } = parsed

  const onMount = async () => {
    if (error) {
      snackbarServerErrorUnknown(enqueueSnackbar, intl)
      return null
    }

    if (code) {
      if (localStorage.getItem("pkce_state") !== state) {
        console.error("error", "invalid state")

        if (!inIframe()) {
          localStorage.removeItem(LOCAL_STORAGE.API_TOKEN)
          localStorage.removeItem(LOCAL_STORAGE.AUTH_MODE)

          window.history.replaceState({}, "", "/")
        }

        snackbarServerErrorUnknown(enqueueSnackbar, intl)
      } else {
        getAccessToken(
          code,
          (request, { access_token, refresh_token }) => {
            if (inIframe()) {
              window.parent.postMessage({ access_token })
            } else {
              const apiToken = `Bearer ${access_token}`
              localStorage.setItem(LOCAL_STORAGE.API_TOKEN, apiToken)
              localStorage.setItem(LOCAL_STORAGE.AUTH_MODE, AUTH_MODE.IDP)
              localStorage.setItem(LOCAL_STORAGE.REFRESH_TOKEN, refresh_token)
              // Replace the history entry to remove the auth code from the browser address bar
              window.history.replaceState({}, "", "/")
              const redirectTo = localStorage.getItem(LOCAL_STORAGE.REDIRECT_URL) || ""

              localStorage.setItem(LOCAL_STORAGE.REDIRECT_URL, "")

              navigate(redirectTo)
              authenticateUser()
            }
          },
          function (request, error) {
            console.error("error", error.error)
            snackbarServerErrorUnknown(enqueueSnackbar, intl)
          }
        )
      }
      // Clean these up since we don't need them anymore
      localStorage.removeItem("pkce_state")
      localStorage.removeItem("pkce_code_verifier")
    } else {
      localStorage.setItem(LOCAL_STORAGE.AUTH_TYPE, AUTH_TYPE.ARCA)

      if (ENV.ENV === "DEV") {
        localStorage.setItem(LOCAL_STORAGE.API_TOKEN, ENV.DEV_TOKEN)
        localStorage.setItem(LOCAL_STORAGE.AUTH_MODE, AUTH_MODE.IDP)
        const redirectTo = localStorage.getItem(LOCAL_STORAGE.REDIRECT_URL) || ""
        navigate(redirectTo)
        authenticateUser()
      } else {
        //localStorage.setItem("redirectUrl", window.location.pathname)
        // Redirect to the authorization server
        window.location.href = await generateLoginUrl(false, arca)
      }
    }
  }

  useEffect(() => {
    onMount()
  }, [])

  return (
    <Container>
      <CircularProgress />
    </Container>
  )
}

export default USBAuthenticator
