import type { PayloadAction } from "@reduxjs/toolkit"
import { createSlice } from "@reduxjs/toolkit"

import { InvoicesSettings, ProductStatisticsGET, StatisticsGET } from "types/api.types"
import { setSelectedTerm } from "utils/common.utils"

import type { KpiState } from "./kpi.types"

const initialState: KpiState = {
  global: {
    loading: true,
    refresh: true,
    currentProductsCount: 0,
    currentStockCount: 0,
    ordersCount: 0,
    salesRevenue: 0,
    ordersCountsGraph: {},
    productsCountsGraph: {},
  },
  product: {
    loading: false,
    refresh: false,
    result: [],
  },
  selectedProduct: {
    ordersCount: 0,
    salesRevenue: 0,
    ordersCountsGraph: {},
  },
}

const kpiSlice = createSlice({
  name: "kpi",
  initialState,
  reducers: {
    get: (state, action: PayloadAction<StatisticsGET>) => {
      const { currentProductsCount, currentStockCount, ordersCount, salesRevenue, ordersCountsGraph, productsCountsGraph, date, section } =
        action.payload
      state.global.refresh = false
      state.global.currentProductsCount = currentProductsCount
      state.global.currentStockCount = currentStockCount
      state.global.ordersCount = ordersCount
      state.global.salesRevenue = salesRevenue
      state.global.ordersCountsGraph = ordersCountsGraph
      state.global.productsCountsGraph = productsCountsGraph
      state.global.date = date
      state.global.section = section
    },
    getProducts: (state, action: PayloadAction<ProductStatisticsGET[] | undefined>) => {
      state.product.refresh = false
      state.product.result = action.payload
    },
    getSelectedProduct: (state, action: PayloadAction<ProductStatisticsGET>) => {
      const { product, date, ordersCount, ordersCountsGraph, salesRevenue } = action.payload
      state.selectedProduct.product = product
      state.selectedProduct.date = date
      state.selectedProduct.ordersCount = ordersCount
      state.selectedProduct.ordersCountsGraph = ordersCountsGraph
      state.selectedProduct.salesRevenue = salesRevenue
    },
    getInvoices: (state, action: PayloadAction<InvoicesSettings>) => {
      state.invoices = action.payload
    },
    refreshProduct: (state) => {
      state.product.refresh = true
    },
    refresh: (state) => {
      state.global.refresh = true
    },
    resetRefresh: (state) => {
      state.global.refresh = false
      state.product.refresh = false
    },
    selectSection: (state, action: PayloadAction<string>) => {
      if (state.global.section) {
        state.global.section.terms = setSelectedTerm(state.global?.section?.terms, action.payload)
        state.global.refresh = true
      }
    },
    selectDate: (state, action: PayloadAction<{ selectedMax?: string; selectedMin?: string }>) => {
      const { selectedMax, selectedMin } = action.payload
      if (state.global.date) {
        if (selectedMax) {
          state.global.date.selectedMax = selectedMax
        }
        if (selectedMin) {
          state.global.date.selectedMin = selectedMin
        }
        state.global.refresh = true
      }
    },
    selectProductDate: (state, action: PayloadAction<{ selectedMax?: string; selectedMin?: string }>) => {
      const { selectedMax, selectedMin } = action.payload
      if (state.selectedProduct.date) {
        if (selectedMax) {
          state.selectedProduct.date.selectedMax = selectedMax
        }
        if (selectedMin) {
          state.selectedProduct.date.selectedMin = selectedMin
        }
        state.product.refresh = true
      }
    },
  },
})

export const {
  get,
  getProducts,
  getSelectedProduct,
  refresh,
  selectSection,
  selectDate,
  resetRefresh,
  refreshProduct,
  selectProductDate,
  getInvoices,
} = kpiSlice.actions
export default kpiSlice.reducer
