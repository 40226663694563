import React from "react"

import { CheckCircleOutlineRounded, WarningAmber } from "@mui/icons-material"
import { Box } from "@mui/material"

import * as S from "./Stock.style"

interface Props {
  stock: number
  warningLevel: number
}

const Stock = ({ stock, warningLevel }: Props) => {
  const warning = stock < warningLevel
  return (
    <S.Container warning={warning}>
      <Box p={1} display="flex" alignItems="center">
        <S.Icon>
          {warning ? <WarningAmber fontSize="inherit" color="inherit" /> : <CheckCircleOutlineRounded fontSize="inherit" color="inherit" />}
        </S.Icon>
        <S.IconStock>{stock}</S.IconStock>
      </Box>
    </S.Container>
  )
}

export default Stock
