import React, { useEffect, useState } from "react"

import { Box, Container, Grid, TextField } from "@mui/material"
import { reverse } from "named-urls"
import { FormattedMessage, useIntl } from "react-intl"
import { useNavigate } from "react-router-dom"

import api from "api"
import { Bill } from "assets/icons/Bill"
import { COLORS } from "assets/styles/colors"
import AbsoluteLoader from "components/loader/Loader"
import PaginationBar from "components/pagination"
import { ProductListItem } from "components/product/productInformationList"
import { useSnackbarPromiseHandler } from "hooks/useSnackbar"
import { useReduxDispatch, useReduxSelector } from "store"
import actions from "store/actions"
import { formatDate, formatPrice } from "utils/format.utils"

import * as SO from "./Orders.styles"

const Orders: React.FC = () => {
  const dispatch = useReduxDispatch()
  const navigate = useNavigate()
  const intl = useIntl()
  const [searchTerm, setSearchTerm] = useState("")
  const { result, page, size, nbResults, q } = useReduxSelector((state) => state.orders)

  const [isLoading, loadData] = useSnackbarPromiseHandler(async (page: number, size: number) => {
    const { data } = await api.orders.getOrders({ page, size, q: searchTerm })
    dispatch(actions.orders.get(data))
  })

  useEffect(() => {
    loadData()
  }, [searchTerm])

  return (
    <Container>
      <Grid container>
        {isLoading && <AbsoluteLoader />}
        <Box display="flex">
          <h1>
            <FormattedMessage id="header.orders" />
          </h1>
        </Box>
      </Grid>
      <Grid item container xs p="6px">
        <TextField
          type="text"
          variant="outlined"
          label={intl.formatMessage({ id: "order.reference.placeholder" })}
          fullWidth
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
      </Grid>
      {result &&
        result?.map((r) => (
          <Grid
            container
            key={r.orderId}
            onClick={() => navigate(reverse(intl.formatMessage({ id: "route.orders.details" }), { orderId: r.orderId }))}
          >
            <SO.Row>
              <Grid item container xs={3} sx={{ ["& > div"]: { marginLeft: 2 } }}>
                <Bill size={40} color={COLORS.GREEN} />
                <ProductListItem titleKey="order.orderId" subtitle={r.orderId} />
              </Grid>
              <Grid item container xs={3} justifyContent="flex-start">
                <ProductListItem titleKey="order.billingAddress" subtitle={r.billingAddress} />
              </Grid>
              <Grid item container xs={3} justifyContent="flex-start">
                <ProductListItem titleKey="order.creationDate" subtitle={formatDate(r.creationDate)} />
              </Grid>
              <Grid item container xs={3} justifyContent="flex-start">
                <ProductListItem titleKey="order.numberItems" subtitle={r.numberItems} />
              </Grid>
              <Grid item container xs={3} justifyContent="flex-start">
                <ProductListItem titleKey="order.totalPrice" subtitle={formatPrice(intl, r.totalPrice)} bold />
              </Grid>
            </SO.Row>
          </Grid>
        ))}
      <PaginationBar count={Math.ceil(nbResults / size)} page={page} onClick={(e, p) => loadData(p, size)} />
    </Container>
  )
}

export default Orders
