import React, { FC, useRef } from "react"

import type { DragSourceMonitor } from "react-dnd"
import { useDrag, useDrop } from "react-dnd"

import api from "api/api"
import { useSnackbarPromiseHandler } from "hooks/useSnackbar"
import { useReduxDispatch } from "store"
import actions from "store/actions"
import { DragItem, Product as ProductType } from "types/common.types"
import { DRAG_TYPES } from "types/enums.types"

import * as S from "./Card.styles"
import Product from "./Product"

export interface CardProps {
  product: ProductType
  index: number
  isProductData?: boolean
}

export const Card: FC<CardProps> = ({ product, index, isProductData }) => {
  const { id } = product
  const ref = useRef<HTMLDivElement>(null)
  const dispatch = useReduxDispatch()
  const [, updateOrder] = useSnackbarPromiseHandler(async (id: number, index: number) => {
    dispatch(actions.products.loading())
    try {
      await api.products.order(id, index)
    } finally {
      dispatch(actions.products.refresh())
    }
  })

  const [{ handlerId, isOver }, drop] = useDrop({
    accept: DRAG_TYPES.PRODUCT,
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
        isOver: monitor.isOver(),
      }
    },
    hover(item: DragItem) {
      if (!ref.current) {
        return
      }
      const dragIndex = item.index
      const hoverIndex = index

      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return
      }

      item.index = hoverIndex
    },
  })

  const [, drag] = useDrag({
    type: DRAG_TYPES.PRODUCT,
    item: () => {
      return { id, index }
    },
    end: async (item, monitor) => {
      const didDrop = monitor.didDrop()
      const { id, index: destIndex } = item
      if (didDrop && index !== destIndex) {
        await updateOrder(id, destIndex)
      }
    },
    collect: (monitor: DragSourceMonitor<{ id: number; index: number }, unknown>) => ({
      isDragging: monitor.isDragging(),
    }),
  })

  drag(drop(ref))

  return (
    <S.Container ref={ref} isOver={isOver} data-handler-id={handlerId}>
      <Product product={product} isProductData={isProductData}/>
    </S.Container>
  )
}
