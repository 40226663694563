import React, { useEffect, useState } from "react"

import { Box, Container, Grid, Typography, Button } from "@mui/material"
import { FormattedMessage, useIntl } from "react-intl"
import { useNavigate, useParams } from "react-router-dom"

import api from "api"
import { COLORS } from "assets/styles/colors"
import AbsoluteLoader from "components/loader/Loader"
import { useSnackbarPromiseHandler } from "hooks/useSnackbar"
import * as S from "screens/order//Stepper.styles"
import Stepper from "screens/order/Stepper"
import OrderedProduct from "screens/products/OrderedProduct"
import type { OrderDetail as TypeOrder, Step } from "types/common.types"
import { RVO_STORE_ORDER_STEP } from "types/enums.types"
import { formatDateDisplay, formatPrice } from "utils/format.utils"

const getSteps = (intl: any, order: TypeOrder | undefined) => {
  let currentStep = 0

  const steps: Step[] = [
    {
      label: order?.step ? RVO_STORE_ORDER_STEP.PLACED : RVO_STORE_ORDER_STEP.TO_VALIDATE,
    },
    {
      label: RVO_STORE_ORDER_STEP.IN_PROCESS,
    },
    {
      label: RVO_STORE_ORDER_STEP.SHIPPED,
    },
    {
      label: RVO_STORE_ORDER_STEP.RECEIVED,
    },
  ]

  if (!order) {
    return { currentStep, steps }
  }
  const dates = [order.creationDate, order.inProcessDate, order.shippedDate, order.receivedDate]

  dates.map((curr, index) => {
    if (curr) {
      currentStep = index
      steps[index].date = formatDateDisplay(intl, curr)
    }
  })

  return { currentStep, steps }
}

const Order = () => {
  const [order, setOrder] = useState<TypeOrder | undefined>()
  const intl = useIntl()
  const navigate = useNavigate()
  const { orderId } = useParams()

  const error = order?.step === RVO_STORE_ORDER_STEP.ERROR || order?.step === RVO_STORE_ORDER_STEP.CANCELLED
  const errorStatus = order?.step
  const errorMessage = order?.step === RVO_STORE_ORDER_STEP.CANCELLED ? order?.statusLabel : ""

  const { currentStep, steps } = getSteps(intl, order)

  const [loading, getOrder] = useSnackbarPromiseHandler(async () => {
    if (orderId) {
      const { data } = await api.orders.getOrder(parseInt(orderId))
      setOrder(data)
    }
  })

  useEffect(() => {
    getOrder()
  }, [])

  return (
    <Container>
      {loading && <AbsoluteLoader />}
      <Button sx={{ mt: 2 }} variant="outlined" color="secondary" onClick={() => navigate(intl.formatMessage({ id: "route.orders" }))}>
        <FormattedMessage id="button.goBack" />
      </Button>
      {order && (
        <h1>
          <FormattedMessage id="order.title" values={{ orderId: orderId, billingAddress: order.billingAddress }} />
        </h1>
      )}
      {order && (
        <Grid container>
          <Grid item xs={3} sx={{ backgroundColor: COLORS.GREY_BG }} p={2} height="fit-content">
            <Stepper
              steps={steps}
              activeStep={currentStep}
              error={error}
              trackingUrl={order?.trackingUrl}
              errorMessage={errorMessage}
              errorStatus={errorStatus}
            />
            <Grid item container xs={12} justifyContent="space-between" pt={2}>
              <S.Price>
                <FormattedMessage id="order.totalPrice" />
              </S.Price>
              <S.Price>{order?.totalPrice && formatPrice(intl, order.totalPrice)}</S.Price>
            </Grid>
          </Grid>
          <Grid item container xs={9} px={2} height="fit-content">
            <Box display="flex" mb={3} sx={{ backgroundColor: COLORS.GREY_BG }} flexDirection="column" width="100%" p={2}>
              <Typography variant="h6" pb={0}>
                {order?.billingAddress}
              </Typography>
              <Typography variant="h6">{order?.shippingAddress}</Typography>
            </Box>
            <Grid item container xs={12} spacing={2}>
              {order?.items.map((p) => (
                <Grid key={p.id} item xs={4}>
                  <OrderedProduct product={p} />
                </Grid>
              ))}
            </Grid>
          </Grid>
        </Grid>
      )}
    </Container>
  )
}

export default Order
