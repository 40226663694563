import * as React from "react"

import { Box } from "@mui/material"
import Step from "@mui/material/Step"
import StepLabel from "@mui/material/StepLabel"
import Stepper from "@mui/material/Stepper"
import { FormattedMessage } from "react-intl"

import type { Step as TypeStep } from "types/common.types"
import { RVO_STORE_ORDER_STEP } from "types/enums.types"

import * as S from "./Stepper.styles"

interface Props {
  steps: TypeStep[]
  activeStep: number
  error: boolean
  errorMessage?: string
  trackingUrl?: string
  errorStatus?: RVO_STORE_ORDER_STEP
}

const VerticalStepper: React.FC<Props> = ({ activeStep, steps, error, errorMessage, trackingUrl, errorStatus }) => (
  <Box>
    <Stepper activeStep={activeStep} orientation="vertical">
      {steps.map(({ label, date }, i) => (
        <Step key={label}>
          <StepLabel error={error && i === activeStep}>
            {(!error || i !== activeStep) && (
              <S.Title>
                <FormattedMessage id={`stepper.${label}`} />
              </S.Title>
            )}
            {error && i === activeStep && errorStatus && (
              <S.Title>
                <FormattedMessage id={`stepper.${errorStatus}`} />
              </S.Title>
            )}
            <div>{date}</div>
            {error && i === activeStep && <div>{errorMessage}</div>}
            {label === RVO_STORE_ORDER_STEP.SHIPPED && trackingUrl && (
              <S.Link href={trackingUrl} target="_blank">
                <FormattedMessage id={`stepper.trackingUrl`} />
              </S.Link>
            )}
          </StepLabel>
        </Step>
      ))}
    </Stepper>
  </Box>
)

export default VerticalStepper
