import type { ProviderContext } from "notistack"
import type { IntlShape } from "react-intl"

import { ServerErrors } from "types/server.enums"

export const snackbarServerErrorRecognized = (
  error: any,
  enqueueSnackbar: ProviderContext["enqueueSnackbar"],
  intl: IntlShape
): boolean => {
  const serverError = error?.response?.data?.error

  if (serverError && Object.values(ServerErrors).includes(serverError)) {
    enqueueSnackbar(intl.formatMessage({ id: `server.error.${serverError}` }), { variant: "error" })
    return true
  }

  return false
}

export const snackbarServerErrorUnknown = (enqueueSnackbar: ProviderContext["enqueueSnackbar"], intl: IntlShape) =>
  enqueueSnackbar(intl.formatMessage({ id: `server.error.${ServerErrors.UNKNOWN_ERROR}` }), { variant: "error" })

export const snackbarHandleServerError = (error: any, enqueueSnackbar: ProviderContext["enqueueSnackbar"], intl: IntlShape) => {
  if (!snackbarServerErrorRecognized(error, enqueueSnackbar, intl)) {
    snackbarServerErrorUnknown(enqueueSnackbar, intl)
  }
}
