import { Term } from "../types/common.types"

export const searchParamToObject = (searchParams: URLSearchParams) => {
  const result: Record<string, any> = {}
  searchParams.forEach((val, key) => {
    if (result[key] && Array.isArray(result[key])) {
      result[key].push(val)
    } else if (result[key]) {
      result[key] = [result[key], val]
    } else {
      result[key] = val
    }
  })
  return result
}

export const getSelectedTerm = (terms?: Array<Term>, defaultValue?: string): string | undefined => {
  if (!terms || !Array.isArray(terms)) {
    return defaultValue
  }
  let selected = defaultValue
  terms.forEach((t) => {
    if (t.checked) {
      selected = t.term
    }
  })
  return selected
}

export const setSelectedTerm = (terms: Array<Term>, term: string): Array<Term> => {
  if (!terms || !Array.isArray(terms)) {
    return terms
  }

  return terms.map((t) => ({ ...t, checked: t.term === term }))
}
