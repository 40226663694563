const routes = {
  "route.orders": "/commandes",
  "route.products": "/produits",
  "route.product.create": "/nouveau-produit",
  "route.orders.details": "/commandes-details/:orderId",
  "route.spotlights": "/mises-en-avant",
  "route.spotlight.create": "/nouvelle-mise-en-avant",
}

export default routes
