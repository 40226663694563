import { styled } from "@mui/material/styles"

import { fonts } from "assets/fonts/fonts"
import { COLORS } from "assets/styles/colors"

export const Title = styled("div")`
  font-family: ${fonts.bold};
  line-height: 20px;
`

export const Link = styled("a")`
  font-family: ${fonts.bold};
  line-height: 20px;
  color: ${COLORS.BLUE};
  text-decoration: underline;
  &:hover {
    color: ${COLORS.BLUE};
    text-decoration: underline;
  }
`

export const Price = styled("div")`
  font-family: ${fonts.bold};
  line-height: 20px;
  font-size: 22px;
`
