import * as yup from "yup"

import { CATEGORIES, PRODUCT_TYPES, SECTIONS } from "types/enums.types"
import { FormField, ProductFields } from "types/form.types"

export const schema = yup
  .object({
    name: yup.string().required(),
    reference: yup.string().required(),
    section: yup.string().required(),
    category: yup.string().required(),
    type: yup.string().required(),
  })
  .required()

export const defaultValues = {
  name: "",
  unitPrice: 0,
  reference: "",
  description: "",
  warningLevel: 0,
  stock: 0,
  category: CATEGORIES.FLAGS,
  section: SECTIONS.POS_AND_PERMANENT_MATERIAL,
  type: "",
  soldBy: 1,
  visible: false,
}

export const inputForm: FormField<ProductFields>[] = [
  {
    key: ProductFields.name,
    type: "string",
    required: true,
  },
  {
    key: ProductFields.reference,
    type: "string",
    required: true,
  },
  {
    key: ProductFields.stock,
    type: "number",
    required: false,
    disabled: true,
  },
  {
    key: ProductFields.warningLevel,
    type: "number",
    inputProps: { inputProps: { min: 0 } },
  },
  {
    key: ProductFields.section,
    type: "select",
    values: Object.values(SECTIONS),
    required: true,
  },
  {
    key: ProductFields.category,
    type: "select",
    values: Object.values(CATEGORIES),
    required: true,
  },
  {
    key: ProductFields.type,
    type: "autocomplete",
    autocomplete: Object.values(PRODUCT_TYPES),
    required: true,
  },
  {
    key: ProductFields.soldBy,
    type: "number",
    required: true,
    inputProps: { inputProps: { min: 1 } },
  },
  {
    key: ProductFields.unitPrice,
    type: "number",
    required: true,
    inputProps: { inputProps: { min: 0, step: ".01" } },
  },
  {
    key: ProductFields.visible,
    type: "boolean",
  },
  {
    key: ProductFields.description,
    type: "multiline",
    size: 12,
  },
]
