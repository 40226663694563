import React from "react"

import { useIntl } from "react-intl"
import { Route, Routes } from "react-router-dom"

import Layout from "layout/Layout"
import Home from "screens/home"
import Order from "screens/order"
import Orders from "screens/orders"
import ProductCreate from "screens/productCreate"
import Products from "screens/products"
import SpotlightCreate from "screens/spotlightCreate"
import Spotlights from "screens/spotlights"

const AppRouter: React.FunctionComponent = () => {
  const intl = useIntl()

  const route = (id: string) => intl.formatMessage({ id })

  return (
    <Layout>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path={route("route.orders")} element={<Orders />} />
        <Route path={route("route.orders.details")} element={<Order />} />
        <Route path={route("route.spotlights")} element={<Spotlights />} />
        <Route path={route("route.spotlight.create")} element={<SpotlightCreate />} />
        <Route path={route("route.products")} element={<Products />} />
        <Route path={route("route.product.create")} element={<ProductCreate />} />
      </Routes>
    </Layout>
  )
}

export default AppRouter
