import React from "react"

import { COLORS } from "assets/styles/colors"

interface Props {
  color: string
  size: number
}

const Bill = (props: Props) => {
  const { color = "black", size = 24 } = props
  return (
    <svg width={size} height={size} fill={color} viewBox="0 0 512 512" stroke={COLORS.GREY_BG_LIGHT}>
      <path d="M474.994 246.001h-74.012v-236a10.002 10.002 0 00-17.071-7.072l-28.329 28.328L327.255 2.93c-3.905-3.905-10.237-3.905-14.143 0l-28.328 28.328L256.459 2.93c-3.905-3.905-10.237-3.905-14.143 0l-28.328 28.328L185.659 2.93c-3.905-3.905-10.237-3.905-14.142 0l-28.328 28.328L114.863 2.93c-3.905-3.905-10.237-3.905-14.143 0L72.392 31.258 44.065 2.93C40.184-.951 33.907-.971 29.992 2.875a9.968 9.968 0 00-2.984 7.389v449.738c0 28.672 23.326 51.998 51.998 51.998h353.99c28.672 0 51.998-23.326 51.998-51.998V256c0-5.523-4.477-9.999-10-9.999zM79.006 492c-17.645 0-31.999-14.355-31.999-31.999V34.156L65.321 52.47c3.905 3.905 10.237 3.905 14.143 0l28.328-28.328L136.12 52.47c3.905 3.905 10.237 3.905 14.143 0l28.328-28.328L206.92 52.47c3.905 3.905 10.237 3.905 14.142 0l28.328-28.328 28.328 28.328c3.905 3.905 10.237 3.905 14.143 0l28.328-28.328 28.328 28.328c3.906 3.905 10.238 3.905 14.142 0l18.329-18.328V256c0 .091.011.18.014.271v203.73c0 12.062 4.14 23.168 11.057 31.999H79.006zm385.988-31.999c0 17.644-14.355 31.999-31.999 31.999s-31.999-14.355-31.999-31.999V266h63.998v194.001z" />
      <path d="M213.995 158.855c-9.925 0-17.999-6.914-17.999-15.411 0-8.498 8.075-15.412 17.999-15.412 9.925 0 17.999 6.914 17.999 15.412 0 5.523 4.477 10 10 10s10-4.477 10-10c0-16.301-11.884-30.055-27.999-34.157v-6.774c0-5.523-4.477-10-10-10s-10 4.477-10 10v6.774c-16.116 4.102-27.999 17.857-27.999 34.157 0 19.525 17.047 35.41 37.999 35.41 9.925 0 17.999 6.914 17.999 15.412s-8.074 15.411-17.999 15.411c-9.925 0-17.999-6.914-17.999-15.411 0-5.523-4.477-10-10-10s-10 4.477-10 10c0 16.301 11.884 30.055 27.999 34.157v8.16c0 5.523 4.477 10 10 10s10-4.477 10-10v-8.16c16.116-4.102 27.999-17.856 27.999-34.157 0-19.525-17.047-35.411-37.999-35.411zM314.461 158.856h-19.49c-5.523 0-10 4.477-10 10s4.477 10 10 10h19.49c5.523 0 10-4.477 10-10s-4.477-10-10-10zM133.02 158.856h-19.49c-5.523 0-10 4.477-10 10s4.477 10 10 10h19.49c5.523 0 10-4.477 10-10s-4.477-10-10-10zM319.52 270.007H161.097c-5.523 0-10 4.477-10 10s4.477 10 10 10H319.52c5.523 0 10-4.477 10-10-.001-5.523-4.477-10-10-10zM115.538 272.937a10.076 10.076 0 00-7.07-2.93c-2.63 0-5.21 1.07-7.07 2.93a10.076 10.076 0 00-2.93 7.07c0 2.63 1.07 5.21 2.93 7.07 1.86 1.86 4.44 2.93 7.07 2.93s5.21-1.07 7.07-2.93c1.86-1.86 2.93-4.44 2.93-7.07s-1.07-5.21-2.93-7.07zM319.52 336.005H161.097c-5.523 0-10 4.477-10 10s4.477 10 10 10H319.52c5.523 0 10-4.477 10-10-.001-5.523-4.477-10-10-10zM115.538 338.935a10.076 10.076 0 00-7.07-2.93c-2.63 0-5.21 1.07-7.07 2.93a10.076 10.076 0 00-2.93 7.07c0 2.63 1.07 5.21 2.93 7.07 1.86 1.86 4.44 2.93 7.07 2.93s5.21-1.07 7.07-2.93c1.86-1.86 2.93-4.44 2.93-7.07s-1.07-5.21-2.93-7.07zM319.52 402.003H161.097c-5.523 0-10 4.477-10 10s4.477 10 10 10H319.52c5.523 0 10-4.477 10-10s-4.477-10-10-10zM115.538 404.933a10.076 10.076 0 00-7.07-2.93c-2.63 0-5.21 1.07-7.07 2.93-1.86 1.86-2.93 4.44-2.93 7.07s1.07 5.21 2.93 7.07c1.86 1.86 4.44 2.93 7.07 2.93s5.21-1.07 7.07-2.93c1.86-1.86 2.93-4.44 2.93-7.07s-1.07-5.21-2.93-7.07z" />
    </svg>
  )
}

export { Bill }
