export const COLORS = {
  GREY_BG: "rgb(242, 242, 242)",
  GREY_BG_LIGHT: "rgb(249,249,249)",
  GREY_DARK: "#888B8D",
  GREEN: "#4caf50",
  BLUE: "#1565c0",
  BLUE_GREY: "#6d7e9c",
  ORANGE: "#ff9800",
  PRIMARY: "#EFDF00",
  PRIMARY_LIGHT: "#FCF9CC",
  RED: "#E53935",
  WHITE: "#fff",
  BLACK: "#000",
  YELLOW_FADED: "#FCF9CC",
}
