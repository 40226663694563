import React, { ChangeEvent } from "react"

import { Pagination } from "@mui/material"
import { styled } from "@mui/material/styles"

const Container = styled("div")`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`

type Props = {
  /* number of elements in a page */
  count: number
  /* current offset */
  page: number | string
  /* action on click */
  onClick: (event: ChangeEvent<unknown>, page: number) => any
}

const PaginationBar: React.FC<Props> = ({ count, page, onClick, ...props }: Props) => {
  return (
    <Container {...props}>
      <Pagination
        shape="rounded"
        count={count}
        page={typeof page === "number" ? page : Number.parseInt(page)}
        onChange={onClick}
        color="primary"
        sx={{
          "& .MuiPaginationItem-root": {
            borderRadius: 0,
          },
        }}
      />
    </Container>
  )
}

export default PaginationBar
