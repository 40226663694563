import React, { useEffect, useState } from "react"

import styled from "@emotion/styled"
import DatePicker from "@mui/lab/DatePicker"
import { TextField } from "@mui/material"
import dateFormat from "date-fns/format"
import { useIntl } from "react-intl"

import { RangeDateFilter as RangeDateFilterType } from "types/common.types"

const Container = styled.div`
  display: flex;
  z-index: 10;
`
const DateContainer = styled.div`
  padding: 6px;
`

interface Props {
  filter: RangeDateFilterType
  onChange: (value: { selectedMax?: string; selectedMin?: string }) => void
}
/*
  Use local state to handle date change while format is incorrect, then trigger onFilterChange action
 */
const RangeDateFilter = ({ filter, onChange }: Props) => {
  const { min, max, selectedMin, selectedMax } = filter

  const apiMaxDate = new Date(max)

  const intl = useIntl()
  const initialState = {
    min: selectedMin ? new Date(selectedMin) : min && new Date(min),
    max: selectedMax ? new Date(selectedMax) : max && new Date(max),
  }

  const [dates, setDates] = useState(initialState)

  /* Reset state on filter change */
  useEffect(() => {
    setDates(initialState)
    // eslint-disable-next-line
  }, [filter])

  const handleFilterChange = (d: { min?: "" | Date | null; max?: "" | Date | null }) => {
    const minMax = d.min ? "min" : "max"
    const value = d[minMax]

    if (value) {
      const newDates = { ...dates, [minMax]: d[minMax] }
      setDates(newDates)

      if (
        !newDates.min ||
        !newDates.max ||
        isNaN(newDates.min.getTime()) ||
        isNaN(newDates.max.getTime()) ||
        newDates.min.getFullYear() < 2020 ||
        newDates.max.getFullYear() < 2020 ||
        newDates.min.getTime() > newDates.max.getTime()
      ) {
        return
      }
      onChange({ [d.min ? "selectedMin" : "selectedMax"]: dateFormat(value, "YYY-MM-dd") })
    }
  }

  return (
    <Container>
      <DateContainer>
        <DatePicker
          label={intl.formatMessage({
            id: "kpi.filter.date.min",
          })}
          views={["year", "month", "day"]}
          maxDate={dates.max}
          minDate={new Date(2022, 0, 1)}
          value={dates.min}
          InputAdornmentProps={{ position: "start" }}
          onChange={(date) => {
            handleFilterChange({ min: date })
          }}
          renderInput={(params) => <TextField {...params} />}
        />
      </DateContainer>
      <DateContainer>
        <DatePicker
          label={intl.formatMessage({
            id: "kpi.filter.date.max",
          })}
          views={["year", "month", "day"]}
          value={dates.max}
          maxDate={new Date(apiMaxDate.getFullYear(), apiMaxDate.getMonth() + 1, 0)}
          minDate={dates.min}
          InputAdornmentProps={{ position: "start" }}
          onChange={(date) => {
            handleFilterChange({ max: date })
          }}
          renderInput={(params) => <TextField {...params} />}
        />
      </DateContainer>
    </Container>
  )
}

export default RangeDateFilter
