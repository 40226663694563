import React from "react"

import styled from "@emotion/styled"
import { FormattedMessage } from "react-intl"

import { fonts } from "assets/fonts/fonts"
import { COLORS } from "assets/styles/colors"

type TitleProps = {
  big?: boolean
}

type Price = {
  type?: string
  including?: number
  excluding?: number
}

enum OBJECT_TYPES {
  ADDRESS = "ADDRESS",
  NUMBER = "NUMBER",
  PRICE = "PRICE",
  UNIT_PRICE = "UNIT_PRICE",
  PRICE_TTC = "PRICE_TTC",
  PRICE_HT = "PRICE_HT",
  DATE = "DATE",
  GEARBOX = "GEARBOX",
  DISTANCE = "DISTANCE",
  LOGISTICAL_STATUS = "LOGISTICAL_STATUS",
  ADMINISTRATIVE_STATUS = "ADMINISTRATIVE_STATUS",
}

const Title = styled.div<TitleProps>`
  font-size: ${(props) => (props.big ? "14px" : "12px")};
  color: ${(props) => (props.big ? COLORS.BLACK : COLORS.GREY_DARK)};
  line-height: 20px;
`

type SubTitleProps = {
  big?: boolean
  bold?: boolean
}

const SubTitleMain = styled.span<SubTitleProps>`
  font-size: ${(props) => (props.big ? "20px" : "14px")};
  line-height: ${(props) => (props.big ? "30px" : "20px")};
  ${(props) => props.bold && `font-family: ${fonts.bold};`}
  white-space: pre-line;
`

const SubTitleSecondary = styled.span<SubTitleProps>`
  font-size: ${(props) => (props.big ? "18px" : "13px")};
  line-height: ${(props) => (props.big ? "30px" : "20px")};
  font-family: ${fonts.regular};
  color: ${(props) => (props.bold ? COLORS.BLACK : COLORS.GREY_DARK)};
  white-space: pre-line;
`

const PriceTaxeIncluding = styled.sup<TitleProps>`
  font-size: ${(props) => (props.big ? "12px" : "8px")};
  top: ${(props) => (props.big ? "-6px" : "-4px")};
  margin-left: 2px;
  margin-right: 6px;
  font-family: ${fonts.regular};
`

const PriceTaxeExcluding = styled.sup<SubTitleProps>`
  font-size: ${(props) => (props.big ? "12px" : "8px")};
  top: ${(props) => (props.big ? "-6px" : "-4px")};
  margin-left: 2px;
  color: ${(props) => (props.bold ? COLORS.BLACK : COLORS.GREY_DARK)};
`

const Container = styled.div<{ row?: boolean }>`
  display: flex;
  ${({ row }) =>
    row
      ? `
          flex-direction: row;
          justify-content: space-between;
        `
      : `
          flex-direction: column;
        `}
  @media print {
    div {
      break-inside: avoid;
    }
  }
`

type PriceContainerProps = {
  column?: boolean
}

const PriceContainer = styled.div<PriceContainerProps>`
  display: flex;
  ${(props) => props.column && "flex-direction: column;"}
`

type Props = {
  titleKey?: string
  subtitle: Array<string | Price> | string | Price | number
  bold?: boolean
  column?: boolean
  big?: boolean
  isIncluding?: boolean
  row?: boolean
}

const ProductListItem: React.FC<Props> = ({ titleKey, subtitle, bold, column, big, isIncluding = false, row }) => {
  return (
    <Container row={row}>
      {titleKey && (
        <Title big={big}>
          <FormattedMessage id={titleKey} />
        </Title>
      )}
      {printItem({ subtitle, big, bold, column, isIncluding })}
    </Container>
  )
}

const printItem: React.FC<Props> = ({ subtitle, big, bold, column, isIncluding }) => {
  /* If print array, first item is bigger than other items */
  if (Array.isArray(subtitle)) {
    return (
      <>
        {subtitle.map((s, i) =>
          i === 0 ? (
            <SubTitleMain key={i} big={big} bold={bold}>
              {s ? printByType(s, big, bold, column, isIncluding) : "-"}
            </SubTitleMain>
          ) : (
            s && (
              <SubTitleSecondary key={i} big={big} bold={bold}>
                {printByType(s, big, bold, column, isIncluding)}
              </SubTitleSecondary>
            )
          )
        )}
      </>
    )
  }
  return (
    <SubTitleMain big={big} bold={bold}>
      {subtitle ? printByType(subtitle, big, bold, column, isIncluding) : "-"}
    </SubTitleMain>
  )
}

const printByType = (
  value: string | Price | number,
  big?: boolean,
  bold?: boolean,
  column?: boolean,
  isIncluding?: boolean
): React.ReactNode => {
  if (typeof value === "object" && value.type === OBJECT_TYPES.PRICE) {
    return printPrice(value, big, bold, column, isIncluding)
  }
  return value
}

const printPrice = (price: Price, big?: boolean, bold?: boolean, column?: boolean, isIncluding?: boolean): React.ReactNode =>
  !isIncluding ? (
    <PriceContainer column={column}>
      <SubTitleMain big={big} bold={bold}>
        {price.including || price.including === 0 ? price.including : "-"}
        {(price.including || price.including === 0) && (
          <PriceTaxeIncluding big={big}>
            <FormattedMessage id="opportunity.taxes.including" />
          </PriceTaxeIncluding>
        )}
      </SubTitleMain>
      {(price.excluding || price.excluding === 0) && (
        <SubTitleSecondary big={big} bold={bold}>
          {price.excluding}
          <PriceTaxeExcluding big={big} bold={bold}>
            <FormattedMessage id="opportunity.taxes.excluding" />
          </PriceTaxeExcluding>
        </SubTitleSecondary>
      )}
    </PriceContainer>
  ) : (
    <PriceContainer column={column}>
      <SubTitleMain big={big} bold={bold}>
        {price.excluding || price.excluding === 0 ? price.excluding : "-"}
        {(price.excluding || price.excluding === 0) && (
          <PriceTaxeIncluding big={big}>
            <FormattedMessage id="opportunity.taxes.excluding" />
          </PriceTaxeIncluding>
        )}
      </SubTitleMain>
    </PriceContainer>
  )

export default ProductListItem
