import MuiCard from "@mui/material/Card"
import { styled } from "@mui/material/styles"

import { fonts } from "assets/fonts/fonts"

export const Container = styled(MuiCard)(() => ({
  padding: "0",
  height: "fit-content",
}))

export const Price = styled("div")`
  font-family: ${fonts.bold};
  line-height: 40px;
  font-size: 36px;
`
export const PriceTax = styled("div")`
  font-size: 14px;
  margin-left: 6px;
`

export const Image = styled("img")`
  height: 200px;
  max-width: 100%;
`

export const Overlay = styled("div")`
  display: none;
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: 1;
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  padding: 12px;
  box-sizing: border-box;
  overflow: hidden;
`

export const ImgContainer = styled("div")`
  position: relative;

  &:hover .overlay {
    display: block;
  }
`
