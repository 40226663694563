import { createTheme } from "@mui/material/styles"

import { fonts } from "assets/fonts/fonts"

import { COLORS } from "./colors"

const titleFonts = [
  fonts.bold,
  "Roboto",
  "-apple-system",
  "BlinkMacSystemFont",
  '"Segoe UI"',
  '"Helvetica Neue"',
  "Arial",
  "sans-serif",
].join(",")

export const theme = createTheme({
  palette: {
    primary: { main: COLORS.PRIMARY },
    secondary: { main: COLORS.GREY_DARK },
    contrastThreshold: 2,
  },
  typography: {
    fontSize: 13,
    fontFamily: [
      fonts.regular,
      "Roboto",
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
    ].join(","),
    h1: {
      fontFamily: titleFonts,
    },
    h2: {
      fontFamily: titleFonts,
    },
    h3: {
      fontFamily: titleFonts,
    },
    h4: {
      fontFamily: titleFonts,
    },
    h5: {
      fontFamily: titleFonts,
    },
    h6: {
      fontFamily: titleFonts,
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          boxShadow: "none !important",
          borderRadius: 0,
          fontFamily: fonts.bold,
        },
        contained: {
          boxShadow: "none",
        },
      },
    },
  },
})
