import React from "react"

import { Box, Button, Modal, Typography } from "@mui/material"
import { FormattedMessage } from "react-intl"

import * as S from "./Modal.style"

interface Props {
  open: boolean
  title: string
  body?: string
  onClose: () => unknown
  onConfirm: () => unknown
}

const ConfirmationModal = ({ open, title, body, onConfirm, onClose }: Props) => {
  return (
    <Modal open={open} onClose={onClose}>
      <S.Container p={2} pt={1} pb={2}>
        <Typography variant="h2">
          <FormattedMessage id={title} />
        </Typography>
        <Typography variant="body1" sx={{ pb: 1, pt: 1 }}>
          <FormattedMessage id={body} />
        </Typography>
        <Box display="flex" justifyContent="flex-end">
          <Button onClick={onClose} color="secondary" variant="outlined" sx={{ ml: 1, mr: 1 }}>
            <FormattedMessage id="modal.confirmation.false" />
          </Button>
          <Button onClick={onConfirm} variant="contained">
            <FormattedMessage id="modal.confirmation.true" />
          </Button>
        </Box>
      </S.Container>
    </Modal>
  )
}

export default ConfirmationModal
