import jwt_decode from "jwt-decode"

import * as CONFIG from "config/env"

import { AUTH_MODE, AUTH_TYPE, LOCAL_STORAGE } from "./authentication-constants.utils"
// PKCE HELPER FUNCTIONS

declare global {
  interface Window {
    __UPDATING_AUTHENTICATION__: boolean
  }
}

// Generate a secure random string using the browser crypto functions
export function generateRandomString() {
  const array = new Uint32Array(28)
  window.crypto.getRandomValues(array)
  return Array.from(array, (dec) => ("0" + dec.toString(16)).substr(-2)).join("")
}

// Calculate the SHA256 hash of the input text.
// Returns a promise that resolves to an ArrayBuffer
export function sha256(plain: string) {
  const encoder = new TextEncoder()
  const data = encoder.encode(plain)
  return window.crypto.subtle.digest("SHA-256", data)
}

// Base64-urlencodes the input string
export function base64urlencode(str: any) {
  // Convert the ArrayBuffer to string using Uint8 array to convert to what btoa accepts.
  // btoa accepts chars only within ascii 0-255 and base64 encodes them.
  // Then convert the base64 encoded to base64url encoded
  //   (replace + with -, replace / with _, trim trailing =)
  return btoa(String.fromCharCode.apply(null, new Uint8Array(str) as any))
    .replace(/\+/g, "-")
    .replace(/\//g, "_")
    .replace(/=+$/, "")
}

// Return the base64-urlencoded sha256 hash for the PKCE challenge
export async function pkceChallengeFromVerifier(v: string) {
  const hashed = await sha256(v)
  return base64urlencode(hashed)
}

export function inIframe() {
  try {
    return window.self !== window.top
  } catch (e) {
    return true
  }
}

/* Return current token
 *  if expired, renew token
 * if > 4h redirect to login
 *  */
/* called before every call. If 401 > invalid token, explicit reconnect */
export async function getTokenSilently(): Promise<any> {
  const jwt = localStorage.getItem(LOCAL_STORAGE.API_TOKEN) || ""
  if (CONFIG.ENV === "DEV") return jwt

  let decoded: any
  try {
    /* decode jwt token in order to have expiration date */
    decoded = jwt_decode(jwt)
  } catch (e) {
    /* No token or malformed token */
  }

  /* If no token, don't renew and go to login page */
  if (!decoded) {
    return jwt
  }

  /* synchronous renewal if expired */
  /*if (decoded.exp * 1000 < new Date().getTime()) {
    updateToken()
  }*/

  /* Check if we need to renew the token before expiration */
  if (decoded.exp * 1000 - 60 * 10 * 1000 < new Date().getTime()) {
    updateToken()
  }

  return jwt
}

export async function updateToken() {
  const authMode = localStorage.getItem(LOCAL_STORAGE.AUTH_MODE)

  if (!window.__UPDATING_AUTHENTICATION__ && authMode === AUTH_MODE.IDP) {
    window.__UPDATING_AUTHENTICATION__ = true

    refreshAccessToken(
      (request, { access_token }) => {
        localStorage.setItem(LOCAL_STORAGE.API_TOKEN, `Bearer ${access_token}`)
        window.__UPDATING_AUTHENTICATION__ = false
      },
      (request, error) => {
        console.error("Error while renewing JWT Token", error.error)
        window.__UPDATING_AUTHENTICATION__ = false
      }
    )

    console.debug("JWT TOKEN Renewal Success", authMode)
  }
}

export function refreshAccessToken(success: (request: any, body: any) => any, error: (request: any, error: any) => any) {
  // const arca = localStorage.getItem(LOCAL_STORAGE.AUTH_TYPE) === AUTH_TYPE.ARCA

  const defaultParams: any = {
    grant_type: "refresh_token",
    client_id: encodeURIComponent(CONFIG.CLIENT_ID_ARCA),
    scope: encodeURIComponent(CONFIG.REQUESTED_SCOPES_ARCA),
    redirect_uri: CONFIG.REDIRECT_URI,
    refresh_token: localStorage.getItem(LOCAL_STORAGE.REFRESH_TOKEN),
  }

  const request = new XMLHttpRequest()
  request.open("POST", CONFIG.TOKEN_ENDPOINT, true)
  request.setRequestHeader("Content-Type", "application/x-www-form-urlencoded; charset=UTF-8")

  request.onload = function () {
    let body = {}
    try {
      body = JSON.parse(request.response)
    } catch (e) {
      console.error("error", e)
    }

    if (request.status === 200) {
      success(request, body)
    } else {
      error(request, body)
    }
  }

  request.onerror = function () {
    error(request, {})
  }

  const body = Object.keys(defaultParams)
    .map((key) => (defaultParams[key] ? key + "=" + defaultParams[key] : ""))
    .join("&")

  request.send(body)
}

export function getAccessToken(code: any, success: (request: any, body: any) => any, error: (request: any, error: any) => any) {
  const defaultParams = {
    grant_type: "authorization_code",
    client_id: encodeURIComponent(CONFIG.CLIENT_ID_ARCA),
    redirect_uri: CONFIG.REDIRECT_URI,
    code_verifier: localStorage.getItem("pkce_code_verifier"),
  }

  const params: any = { ...defaultParams, code }

  const request = new XMLHttpRequest()
  request.open("POST", CONFIG.TOKEN_ENDPOINT, true)
  request.setRequestHeader("Content-Type", "application/x-www-form-urlencoded; charset=UTF-8")

  request.onload = function () {
    let body = {}
    try {
      body = JSON.parse(request.response)
    } catch (e) {
      console.error("error", e)
    }

    if (request.status === 200) {
      success(request, body)
    } else {
      error(request, body)
    }
  }

  request.onerror = function () {
    error(request, {})
  }

  const body = Object.keys(params)
    .map((key) => (params[key] ? key + "=" + params[key] : ""))
    .join("&")

  request.send(body)
}

export async function generateLoginUrl(silent = false, arca: boolean = localStorage.getItem(LOCAL_STORAGE.AUTH_TYPE) === AUTH_TYPE.ARCA) {
  const state = generateRandomString()
  localStorage.setItem("pkce_state", state)

  // Create and store a new PKCE code_verifier (the plaintext random secret)
  const code_verifier = generateRandomString()
  localStorage.setItem("pkce_code_verifier", code_verifier)

  // Hash and base64-urlencode the secret to use as the challenge
  const code_challenge = await pkceChallengeFromVerifier(code_verifier)

  let params: any = {
    response_type: "code",
    client_id: encodeURIComponent(CONFIG.CLIENT_ID_ARCA),
    state: encodeURIComponent(state),
    scope: encodeURIComponent(CONFIG.REQUESTED_SCOPES_ARCA),
    acr_values: CONFIG.ACR_VALUES_ARCA,
    redirect_uri: encodeURIComponent(CONFIG.REDIRECT_URI),
    code_challenge: encodeURIComponent(code_challenge),
    code_challenge_method: "S256",
  }

  if (silent) {
    params = { ...params, prompt: "none", response_mode: "web_message" }
  }

  // property prompt is missing in object literal :(
  const parameters = Object.keys(params)

    .map((key) => (params[key] ? key + "=" + params[key] : ""))
    .join("&")

  // Build the authorization URL
  return CONFIG.AUTHORIZATION_ENDPOINT + "?" + parameters
}
