import React, { useEffect } from "react"

import { Add } from "@mui/icons-material"
import { Box, Button, Grid, Grow, Typography } from "@mui/material"
import { DndProvider } from "react-dnd"
import { HTML5Backend } from "react-dnd-html5-backend"
import { FormattedMessage, useIntl } from "react-intl"
import { useNavigate } from "react-router-dom"

import api from "api/api"
import AbsoluteLoader from "components/loader"
import Spotlight from "components/spotlight"
import { Card } from "components/spotlight/Card"
import { useSnackbarPromiseHandler } from "hooks/useSnackbar"
import { useReduxDispatch, useReduxSelector } from "store"
import actions from "store/actions"

import * as S from "./Spotlights.styles"

const Spotlights = () => {
  const navigate = useNavigate()
  const intl = useIntl()
  const dispatch = useReduxDispatch()

  const [isLoading, loadData] = useSnackbarPromiseHandler(async () => {
    try {
      const { data } = await api.spotlights.get()
      dispatch(actions.spotlights.get(data))
    } catch (e) {
      dispatch(actions.spotlights.resetRefresh())
    }
  })

  const { published, unpublished, refresh, loading } = useReduxSelector((state) => state.spotlights)

  useEffect(() => {
    if (refresh) {
      loadData()
    }
  }, [refresh])

  const load = isLoading || loading

  return (
    <Box p={2} sx={{ maxWidth: 1600 }} display="flex" justifyContent="center" m="auto">
      {load && <AbsoluteLoader />}
      <Grid container spacing={6}>
        <Grid item container xs={6}>
          <DndProvider backend={HTML5Backend}>
            <Box width="100%">
              <Typography variant="h4" p={2} pb={1}>
                <FormattedMessage id="screen.mea.order" values={{ nbResults: published.length }} />
              </Typography>
              <Box>
                <S.DropContainer>
                  <Grid container spacing={1}>
                    {published?.map((s, index) => (
                      <Grow in key={s.id} timeout={Math.min(index * 250, 2000)}>
                        <Grid item key={s.id} xs={6}>
                          <Card index={s.order} spotlight={s} />
                        </Grid>
                      </Grow>
                    ))}
                  </Grid>
                </S.DropContainer>
              </Box>
            </Box>
          </DndProvider>
        </Grid>
        <Grid item container xs={6} height="fit-content">
          {unpublished && Array.isArray(unpublished) && (
            <Typography variant="h4" p={2} pb={0}>
              <FormattedMessage id="screen.mea.nbResults" values={{ nbResults: unpublished.length }} />
            </Typography>
          )}

          <Grid container spacing={2} sx={{ maxHeight: "70vh", overflowY: "auto" }} mt={0} p={2} pt={1}>
            {unpublished?.map((s, index) => (
              <Grow in key={s.id} timeout={Math.min((index + 1) * 350, 2000)}>
                <Grid sx={{ cursor: "pointer" }} item xs={6}>
                  <Spotlight
                    spotlight={s}
                    onClick={() => {
                      navigate(intl.formatMessage({ id: "route.spotlight.create" }), { state: { spotlight: s } })
                    }}
                  />
                </Grid>
              </Grow>
            ))}
          </Grid>
          <Box width="100%" display="flex" justifyContent="flex-end" mt={4} height="fit-content">
            <Button
              variant="contained"
              onClick={() => {
                navigate(intl.formatMessage({ id: "route.spotlight.create" }))
              }}
            >
              <Add /> <FormattedMessage id="button.mea.add" />
            </Button>
          </Box>
        </Grid>
      </Grid>
    </Box>
  )
}

export default Spotlights
