import React, { useState } from "react"

import { yupResolver } from "@hookform/resolvers/yup"
import { InfoOutlined } from "@mui/icons-material"
import DeleteIcon from "@mui/icons-material/Delete"
import DatePicker from "@mui/lab/DatePicker"
import {
  Box,
  Button,
  Checkbox,
  Container,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  Radio,
  RadioGroup,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material"
import { Controller, useForm } from "react-hook-form"
import { FormattedMessage, useIntl } from "react-intl"
import { useLocation, useNavigate } from "react-router-dom"
import * as yup from "yup"

import api from "api/api"
import { fonts } from "assets/fonts/fonts"
import { COLORS } from "assets/styles/colors"
import Dropzone from "components/dropzone/Dropzone"
import AbsoluteLoader from "components/loader"
import ConfirmationModal from "components/modal/ConfirmationModal"
import { useSnackbarPromiseHandler } from "hooks/useSnackbar"
import { useReduxDispatch } from "store"
import actions from "store/actions"
import { Spotlight } from "types/common.types"
import { ADJUST_METHODS } from "types/enums.types"
import { SpotlightForm } from "types/form.types"
import { formatDateDisplay } from "utils/format.utils"

const schema = yup
  .object({
    title: yup.string().required(),
  })
  .required()

const defaultValues = {
  title: "",
  subtitle: "",
  link: "",
  adjustment: ADJUST_METHODS.ADJUST,
  published: false,
  startDate: new Date(),
  endDate: null,
  image: null,
}

enum TextForm {
  "subtitle" = "subtitle",
}

enum DateForm {
  "startDate" = "startDate",
  "endDate" = "endDate",
}

const dateProps = {
  [DateForm.startDate]: {
    required: true,
  },
  [DateForm.endDate]: {},
}

const SpotlightCreate = () => {
  const intl = useIntl()
  const [modal, setModal] = useState(false)
  const location = useLocation()
  const dispatch = useReduxDispatch()
  const navigate = useNavigate()

  const state = location.state as { spotlight: Spotlight }
  const { spotlight } = state || {}
  const { image, id, creationDate, modificationDate, ...rest } = spotlight || {}

  const [loadingCreate, onSubmit] = useSnackbarPromiseHandler(async (data: SpotlightForm) => {
    const { image, ...rest } = data
    const img = image && Array.isArray(image) ? image[0] : undefined

    await api.spotlights.create(rest, img)
    dispatch(actions.spotlights.refresh())

    navigate(intl.formatMessage({ id: "route.spotlights" }))
  }, `snackbar.success.spotlight.${id ? "edit" : "create"}`)

  const [loadingDelete, onDelete] = useSnackbarPromiseHandler(async () => {
    await api.spotlights.delete(id.toString())
    dispatch(actions.spotlights.refresh())
    navigate(intl.formatMessage({ id: "route.spotlights" }))
  }, "snackbar.success.spotlight.delete")

  const loading = loadingCreate || loadingDelete

  const { control, handleSubmit, register, setValue } = useForm<SpotlightForm>({
    defaultValues: { ...defaultValues, ...rest, id },
    resolver: yupResolver(schema),
  })

  const sxTheme = { backgroundColor: "white" }

  return (
    <Container>
      {loading && <AbsoluteLoader />}
      <ConfirmationModal
        body="modal.delete.mea.body"
        title="modal.delete.title"
        open={modal}
        onClose={() => setModal(false)}
        onConfirm={onDelete}
      />
      {window.history.length > 2 && (
        <Box mt={2} ml={2}>
          <Button color="secondary" variant="outlined" onClick={() => navigate(-1)}>
            <FormattedMessage id="button.goBack" />
          </Button>
        </Box>
      )}
      <Typography variant="h2" p={2} pb={3} pt={1}>
        <FormattedMessage id="screen.mea.title" />
      </Typography>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container bgcolor={COLORS.GREY_BG} p={2} spacing={2}>
          <Grid item xs={5}>
            <Dropzone
              onChange={(e) => {
                setValue("image", e)
              }}
              name="image"
              maxHeight={150}
              value={image}
            />
          </Grid>
          <Grid item container spacing={2} xs={7} lg={7} height="fit-content">
            <Grid item xs={12}>
              <Controller
                name="title"
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <TextField
                    {...field}
                    variant="outlined"
                    label={intl.formatMessage({ id: "form.mea.title" })}
                    fullWidth
                    required
                    sx={sxTheme}
                  />
                )}
              />
            </Grid>
            {Object.values(TextForm).map((k) => (
              <Grid item xs={12} key={k}>
                <Controller
                  name={k}
                  control={control}
                  render={({ field }) => (
                    <TextField {...field} variant="outlined" label={intl.formatMessage({ id: `form.mea.${k}` })} fullWidth sx={sxTheme} />
                  )}
                />
              </Grid>
            ))}
            <Grid item xs={12}>
              <Controller
                name={"link"}
                control={control}
                render={({ field }) => (
                  <Tooltip
                    arrow
                    title={
                      <Box sx={{ fontSize: 14 }}>
                        <div>{intl.formatMessage({ id: "screen.mea.link.tooltip.1" })}</div>
                        <div>{intl.formatMessage({ id: "screen.mea.link.tooltip.2" })}</div>
                      </Box>
                    }
                  >
                    <TextField {...field} variant="outlined" label={intl.formatMessage({ id: `form.mea.link` })} fullWidth sx={sxTheme} />
                  </Tooltip>
                )}
              />
            </Grid>
          </Grid>
          <Grid item container xs={12}>
            <FormControl>
              <Box sx={{ fontFamily: fonts.bold }} display="flex" alignItems="center">
                <FormattedMessage id="form.mea.adjust" />
                <Tooltip
                  arrow
                  title={
                    <Box sx={{ fontSize: 14 }}>
                      <div>{intl.formatMessage({ id: "screen.mea.adjustment.tooltip.1" })}</div>
                      <br />
                      <div>{intl.formatMessage({ id: "screen.mea.adjustment.tooltip.2" })}</div>
                    </Box>
                  }
                >
                  <Box sx={{ fontSize: 22, color: COLORS.GREY_DARK, cursor: "pointer" }} pl={1} pr={1} display="flex" alignItems="center">
                    <InfoOutlined fontSize="inherit" color="inherit" />
                  </Box>
                </Tooltip>
              </Box>
              <RadioGroup
                aria-labelledby="adjust-group-label"
                defaultValue={spotlight?.adjustment ?? defaultValues.adjustment}
                name="adjustment"
                sx={{ display: "flex", flexDirection: "row" }}
              >
                <FormControlLabel
                  value={ADJUST_METHODS.ADJUST}
                  control={<Radio {...register("adjustment")} />}
                  label={intl.formatMessage({ id: `form.mea.adjust.${ADJUST_METHODS.ADJUST}` })}
                />
                <FormControlLabel
                  value={ADJUST_METHODS.FILL}
                  control={<Radio {...register("adjustment")} />}
                  label={intl.formatMessage({ id: `form.mea.adjust.${ADJUST_METHODS.FILL}` })}
                />
              </RadioGroup>
            </FormControl>
          </Grid>
          <Grid item container xs={12} spacing={2}>
            {Object.values(DateForm).map((k) => (
              <Grid item key={k}>
                <Controller
                  name={k}
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <DatePicker
                      label={intl.formatMessage({ id: `form.mea.${k}` })}
                      inputFormat="dd/MM/yyyy"
                      {...field}
                      renderInput={(params: any) => <TextField {...params} sx={sxTheme} {...dateProps[k]} />}
                    />
                  )}
                />
              </Grid>
            ))}
          </Grid>
          <Grid item container xs={12} alignItems="center">
            <Box sx={{ fontFamily: fonts.bold }}>
              <FormattedMessage id="form.mea.published" />
            </Box>
            <Controller
              name="published"
              control={control}
              rules={{ required: true }}
              render={({ field }) => <Checkbox {...field} checked={field.value} />}
            />
          </Grid>
          {spotlight && spotlight.creationDate && (
            <Grid item container xs={12}>
              <FormattedMessage id="form.mea.creationDate" values={{ date: formatDateDisplay(intl, creationDate) }} />
            </Grid>
          )}
          {spotlight && spotlight.modificationDate && (
            <Grid item container xs={12}>
              <FormattedMessage id="form.mea.modificationDate" values={{ date: formatDateDisplay(intl, modificationDate) }} />
            </Grid>
          )}
          <Grid item container xs={12} justifyContent="flex-end">
            <Button type="submit" variant="contained">
              <FormattedMessage id="button.validate" />
            </Button>
            {id && (
              <IconButton
                onClick={() => setModal(true)}
                size="large"
                edge="start"
                color="inherit"
                aria-label="menu"
                sx={{ ml: 2, fontSize: 28 }}
              >
                <DeleteIcon fontSize={"inherit"} />
              </IconButton>
            )}
          </Grid>
        </Grid>
      </form>
    </Container>
  )
}

export default SpotlightCreate
