import React from "react"

import type { SelectChangeEvent } from "@mui/material"
import { OutlinedInput } from "@mui/material"
import FormControl from "@mui/material/FormControl"
import InputLabel from "@mui/material/InputLabel"
import MenuItem from "@mui/material/MenuItem"
import Select from "@mui/material/Select"
import { FormattedMessage } from "react-intl"

import { Term } from "types/common.types"
import { getSelectedTerm } from "utils/common.utils"

type Props = {
  optionsPrefix: string
  placeholderKey: string
  onClick: (value: string) => void
  options: Array<Term>
}

const defaultValue = "default_value"

/* SelectButton with translation keys */
function TermSelectFilter({ optionsPrefix, placeholderKey, options, onClick }: Props) {
  const value = getSelectedTerm(options, defaultValue)

  function handleChange(event: SelectChangeEvent) {
    const { value } = event.target
    onClick(value)
  }

  return (
    <FormControl variant="outlined" sx={{ width: "100%" }}>
      <InputLabel htmlFor={`select-${placeholderKey}`}>
        <FormattedMessage id={placeholderKey} />
      </InputLabel>
      <Select
        value={value}
        onChange={handleChange}
        input={<OutlinedInput name="select" label={<FormattedMessage id={placeholderKey} />} id={`select-${placeholderKey}`} />}
      >
        <MenuItem value={defaultValue}>
          <em>
            <FormattedMessage id={`${optionsPrefix}.default`} />
          </em>
        </MenuItem>
        {options.map((term) => (
          <MenuItem value={term.term} key={term.term}>
            <FormattedMessage id={`${optionsPrefix}.${term.term}`} />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}

export default TermSelectFilter
