import { CATEGORIES, SECTIONS } from "./enums.types"

export type SpotlightForm = {
  id?: number
  title: string
  subtitle: string
  link: string
  adjustment: string
  published: boolean
  startDate: Date
  endDate: Date | null
  image?: File[] | null
}

export type ProductForm = {
  id?: number
  name: string
  unitPrice: number
  stock?: number
  reference: string
  description: string
  warningLevel: number
  category: CATEGORIES
  section: SECTIONS
  type: string
  soldBy: number
  visible: boolean
  image?: File[] | null
}

export type FormField<keyType> = {
  key: keyType
  type: "string" | "number" | "select" | "autocomplete" | "boolean" | "multiline"
  required?: boolean
  size?: number
  disabled?: boolean
  inputProps?: Record<string, unknown>
  values?: string[]
  autocomplete?: string[]
}

export enum ProductFields {
  "name" = "name",
  "reference" = "reference",
  "stock" = "stock",
  "warningLevel" = "warningLevel",
  "section" = "section",
  "category" = "category",
  "type" = "type",
  "soldBy" = "soldBy",
  "unitPrice" = "unitPrice",
  "visible" = "visible",
  "description" = "description",
}
