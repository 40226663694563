import { combineReducers } from "@reduxjs/toolkit"

import auth from "./auth/auth.reducer"
import kpi from "./kpi/kpi.reducer"
import orders from "./orders/orders.reducer"
import products from "./products/products.reducer"
import spotlights from "./spotlights/spotlights.reducer"

const rootReducer = combineReducers({
  auth,
  kpi,
  spotlights,
  products,
  orders,
})

export default rootReducer
