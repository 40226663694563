import React, { useEffect, useState } from "react"

import { yupResolver } from "@hookform/resolvers/yup/dist/yup"
import { Box, Button, Grid, Modal, OutlinedInput, styled, TextField, Typography } from "@mui/material"
import CircularProgress from "@mui/material/CircularProgress"
import FormControl from "@mui/material/FormControl"
import InputLabel from "@mui/material/InputLabel"
import MenuItem from "@mui/material/MenuItem"
import Select from "@mui/material/Select"
import { Controller, useForm } from "react-hook-form"
import { FormattedMessage, useIntl } from "react-intl"
import * as yup from "yup"

import { InvoicesSettings, InvoicesSettingsForm } from "types/api.types"

const Container = styled(Box)(() => ({
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  backgroundColor: "white",
  outline: "none",
  maxHeight: "100vh",
  overflow: "auto",
}))

const DEFAULT_YEAR = 2023

const pad = (num: number | string, size: number) => {
  let res = num.toString()
  while (res.length < size) res = "0" + res
  return res
}

const getSchema = () => {
  const yearSchema: any = {}
  const schema: any = {}

  for (let i = 1; i < 13; i++) {
    yearSchema[i] = yup.number().optional()
  }

  for (let i = DEFAULT_YEAR; i <= new Date().getFullYear() + 1; i++) {
    schema[i] = yup.object(yearSchema)
  }

  return yup.object(schema).required()
}

const getMaxDays = (year: number) => {
  const result = []
  for (let i = 1; i < 13; i++) {
    result.push(new Date(year, i, 0).getDate())
  }
  return result
}

const getSelectOptions = () => {
  const options = []
  for (let i = DEFAULT_YEAR; i <= new Date().getFullYear() + 1; i++) {
    options.push(i)
  }
  return options
}

interface Props {
  open: boolean
  loading: boolean
  onClose: () => unknown
  onConfirm: (data: string[]) => unknown
  invoices?: InvoicesSettings
}

const SettingsModal = ({ open, loading, onClose, onConfirm, invoices }: Props) => {
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear())
  const intl = useIntl()

  const days = getMaxDays(selectedYear)
  const settings = invoices?.[selectedYear] ?? undefined

  const onSubmit = (data: InvoicesSettingsForm) => {
    const results: string[] = []

    Object.keys(data).forEach((year) => {
      Object.keys(data[year]).forEach((month) => {
        if (data[year][month] != undefined) {
          const d = `${year}-${pad(month, 2)}-${pad(data[year][month], 2)}`
          if (new Date(d) > new Date()) {
            results.push(`${year}-${pad(month, 2)}-${pad(data[year][month], 2)}`)
          }
        }
      })
    })

    onConfirm(results)
  }

  const getDefaultValues = () => {
    const defaultValues: any = {}
    for (let i = DEFAULT_YEAR; i <= new Date().getFullYear() + 1; i++) {
      defaultValues[i] = {}
    }

    for (let i = DEFAULT_YEAR; i <= new Date().getFullYear() + 1; i++) {
      for (let j = 1; j < 13; j++) {
        if (invoices?.[i]?.[j].exportDate) {
          defaultValues[i][j] = new Date(invoices[i][j].exportDate).getDate()
        } else {
          defaultValues[i][j] = undefined
        }
      }
    }

    return defaultValues
  }

  const { control, handleSubmit, reset } = useForm({
    defaultValues: getDefaultValues(),
    resolver: yupResolver(getSchema()),
  })

  useEffect(() => {
    reset(getDefaultValues())
  }, [invoices])

  return (
    <Modal open={open} onClose={onClose}>
      <Container p={2} pt={1} pb={2} sx={{ width: { xs: "90%", sm: "80%", md: "70%", lg: "60%" } }}>
        <Typography variant="h3" gutterBottom>
          <FormattedMessage id={"modal.invoices.title"} />
        </Typography>
        {loading && (
          <Box display="flex" alignItems="center" justifyContent="center" py={6}>
            <CircularProgress size={150} />
          </Box>
        )}
        {!loading && invoices && (
          <>
            <FormControl variant="outlined" sx={{ width: "100%", mt: 1, mb: 2 }}>
              <InputLabel htmlFor={`select-year`}>
                <FormattedMessage id={intl.formatMessage({ id: "modal.invoices.year" })} />
              </InputLabel>
              <Select
                value={selectedYear}
                onChange={(e) => setSelectedYear(parseInt(e.target.value as string))}
                input={<OutlinedInput name="select" label={<FormattedMessage id={"modal.invoices.year"} />} id={`select-year`} />}
              >
                {getSelectOptions().map((y) => (
                  <MenuItem value={y} key={y}>
                    {y}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <form onSubmit={handleSubmit(onSubmit)}>
              <Grid container>
                {settings &&
                  Object.keys(settings).map((k, i) => (
                    <Grid key={`${selectedYear}.${k}`} item xs={12} sm={6} mb={2} p={1}>
                      <Controller
                        name={`${selectedYear}.${k}`}
                        control={control}
                        rules={{ required: true }}
                        render={({ field }) => (
                          <TextField
                            {...field}
                            disabled={!invoices?.[selectedYear]?.[parseInt(k)].editable}
                            fullWidth
                            type="number"
                            inputProps={{ min: 1, max: days[i] }}
                            variant="standard"
                            label={<FormattedMessage id={`settings.invoices.${i}`} />}
                          />
                        )}
                      />
                    </Grid>
                  ))}
              </Grid>
              <Box display="flex" justifyContent="flex-end">
                <Button onClick={onClose} color="secondary" variant="outlined" sx={{ ml: 1, mr: 1 }}>
                  <FormattedMessage id="modal.confirmation.false" />
                </Button>
                <Button variant="contained" type="submit">
                  <FormattedMessage id="modal.confirmation.true" />
                </Button>
              </Box>
            </form>
          </>
        )}
      </Container>
    </Modal>
  )
}

export default SettingsModal
