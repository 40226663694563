import React, { useEffect } from "react"

import { Add } from "@mui/icons-material"
import { Box, Button, Container, Typography } from "@mui/material"
import { DndProvider } from "react-dnd"
import { HTML5Backend } from "react-dnd-html5-backend"
import { FormattedMessage, useIntl } from "react-intl"
import { useNavigate } from "react-router-dom"

import api from "api/api"
import AbsoluteLoader from "components/loader"
import { Card } from "components/product/Card"
import Toggle from "components/toggle/Toggle"
import { useSnackbarPromiseHandler } from "hooks/useSnackbar"
import { useReduxDispatch, useReduxSelector } from "store"
import actions from "store/actions"
import { SECTIONS } from "types/enums.types"

const Products = () => {
  const dispatch = useReduxDispatch()
  const navigate = useNavigate()
  const intl = useIntl()
  const { products, section, refresh, loading, sectionsCounts } = useReduxSelector((state) => state.products)

  const [isLoading, loadData] = useSnackbarPromiseHandler(async () => {
    try {
      const { data } = await api.products.get(section)
      dispatch(actions.products.get(data))
    } catch (e) {
      dispatch(actions.spotlights.resetRefresh())
    }
  })

  useEffect(() => {
    if (refresh) {
      loadData()
    }
  }, [refresh])

  const load = loading || isLoading
  const counts: Record<string, number> = sectionsCounts?.terms.reduce((acc, cur) => ({ [cur.term]: cur.count, ...acc }), {}) ?? {}

  return (
    <Container>
      {load && <AbsoluteLoader />}
      <Typography variant="h2" p={2} pb={1} pt={1}>
        <FormattedMessage id="screen.products.title" />
      </Typography>
      <Box display="flex">
        {Object.values(SECTIONS).map((c) => (
          <Box key={c} p={2} pb={1}>
            <Toggle
              active={section === c}
              title={c}
              count={counts && counts[c] ? counts[c] : 0}
              onClick={() => dispatch(actions.products.selectSection(c))}
            />
          </Box>
        ))}
      </Box>
      <Box p={2} sx={{ maxHeight: "70vh", overflowY: "auto" }}>
        <DndProvider backend={HTML5Backend}>
          {products &&
            products.map((p) => (
              <Box key={p.id} pb={1}>
                <Card product={p} index={p.order} />
              </Box>
            ))}
        </DndProvider>
      </Box>
      <Box width="100%" display="flex" justifyContent="flex-end" mt={4} height="fit-content">
        <Button
          variant="contained"
          onClick={() => {
            navigate(intl.formatMessage({ id: "route.product.create" }))
            dispatch(actions.products.resetProduct())
          }}
        >
          <Add /> <FormattedMessage id="button.product.add" />
        </Button>
      </Box>
    </Container>
  )
}

export default Products
