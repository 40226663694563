import React, { useState } from "react"

import { Box, Container as MuiContainer, styled } from "@mui/material"
import { useLocation } from "react-router-dom"

import USBAuthenticator from "components/usbAuthenticator/USBAuthenticator"
import { LOCAL_STORAGE } from "utils/authentication-constants.utils"

const Container = styled(MuiContainer)({
  flex: 1,
})

const Authentication: React.FC = () => {
  const [usb, setUsb] = useState(false)
  const authMode = localStorage.getItem(LOCAL_STORAGE.AUTH_MODE)
  const authToken = localStorage.getItem(LOCAL_STORAGE.API_TOKEN)
  const location = useLocation()
  // const { code } = queryString.parse(location.search);

  return (
    <Box p={6}>
      <USBAuthenticator />
    </Box>
  )
}

export default Authentication
