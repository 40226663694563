import styled from "@emotion/styled"

import { fonts } from "assets/fonts/fonts"
import { COLORS } from "assets/styles/colors"
import { ADJUST_METHODS } from "types/enums.types"

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  background-color: white;
  justify-content: space-between;
  height: 100%;
`

export const Title = styled.div`
  font-family: ${fonts.bold};
`

export const SubTitle = styled.div``
export const DateContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`
type ImageProps = {
  adjust: string
  image: boolean
}
export const Image = styled.img`
  max-height: 200px;
  width: 100%;
  height: 100%;
  object-fit: ${(props: ImageProps) => (props.adjust === ADJUST_METHODS.FILL ? "cover" : "contain")};
  background: ${(props: ImageProps) => (props.image ? "inherit" : "grey")};
`

export const ImageContainer = styled.div`
  max-height: 200px;
`

export const IconContainer = styled.div`
  position: absolute;
  right: 4px;
  top: 4px;
  cursor: pointer;
  z-index: 10;
  background-color: rgba(255, 255, 255, 0.5);
  height: 24px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
`
export const Overlay = styled.div`
  display: none;
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: 1;
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
`

export const Card = styled.div`
  height: 100%;
  position: relative;
  border: 1px solid ${COLORS.GREY_BG};
  &:hover .overlay {
    display: block;
  }
`
