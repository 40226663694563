import styled from "@emotion/styled"

import { COLORS } from "assets/styles/colors"

type ContainerProps = {
  isOver: boolean
}

export const Container = styled.div`
  &:hover {
    outline: 2px dashed ${(props: ContainerProps) => (props.isOver ? COLORS.PRIMARY : COLORS.GREY_DARK)};
  }
  outline: 2px dashed ${(props: ContainerProps) => (props.isOver ? COLORS.PRIMARY : "white")};
  padding: 6px;
  cursor: move;
`
