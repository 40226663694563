import type { IntlShape } from "react-intl"

export function formatDate(date: Date) {
  const d = new Date(date)
  let month = "" + (d.getMonth() + 1)
  let day = "" + d.getDate()
  const year = d.getFullYear()

  if (month.length < 2) month = "0" + month
  if (day.length < 2) day = "0" + day

  return [year, month, day].join("-")
}

export const formatDateDisplay = (intl: IntlShape, value?: unknown) => {
  if (typeof value === "string" || value instanceof Date) {
    return intl.formatDate(new Date(value))
  }

  return "-"
}

export const formatNull = (value?: unknown) => (value ? value + "" : "-")

export const formatPrice = (intl: IntlShape, value?: unknown) => {
  if (typeof value === "number" && Number.isFinite(value)) {
    return intl.formatNumber(value, {
      style: "currency",
      currency: "EUR",
      minimumFractionDigits: 0,
      maximumFractionDigits: 2,
    })
  }

  return "-"
}

const numberStyle = {
  minimumFractionDigits: 0,
  maximumFractionDigits: 0,
}

export const formatNumber = (intl: IntlShape, value: number): string => {
  return intl.formatNumber(value, numberStyle).replace(/ /gi, "\u00A0")
}
