export enum ADJUST_METHODS {
  ADJUST = "ADJUST",
  FILL = "FILL",
}

export enum DRAG_TYPES {
  CARD = "CARD",
  PRODUCT = "PRODUCT",
}

export enum CATEGORIES {
  FLAGS = "FLAGS",
  STICKERS = "STICKERS",
  POSTERS = "POSTERS",
  PLATE_COVERS = "PLATE_COVERS",
  PRICE_HOLDERS = "PRICE_HOLDERS",
  GOODIES = "GOODIES",
  OTHERS = "OTHERS",
}

export enum SECTIONS {
  POS_AND_PERMANENT_MATERIAL = "POS_AND_PERMANENT_MATERIAL",
  POS_NATIONAL_COMMUNICATION = "POS_NATIONAL_COMMUNICATION",
  GOODIES = "GOODIES",
  ADDITIONAL_TOOLS_AND_MATERIALS = "ADDITIONAL_TOOLS_AND_MATERIALS",
}

export enum PRODUCT_TYPES {
  FLAG = "FLAG",
  PRICE_HOLDERS = "PRICE_HOLDERS",
  THREE = "THREE",
  T_FIVE = "T_FIVE",
  FIFTY = "FIFTY",
}

export enum RVO_STORE_ORDER_STEP {
  TO_VALIDATE = "TO_VALIDATE",
  PLACED = "PLACED",
  ERROR = "ERROR",
  CANCELLED = "CANCELLED",
  IN_PROCESS = "IN_PROCESS",
  SHIPPED = "SHIPPED",
  RECEIVED = "RECEIVED",
}
