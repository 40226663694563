import React, { Fragment, useEffect, useState } from "react"

import { yupResolver } from "@hookform/resolvers/yup"
import DeleteIcon from "@mui/icons-material/Delete"
import { Box, Button, Container, Grid, IconButton, TextField, Typography } from "@mui/material"
import { useForm } from "react-hook-form"
import { FormattedMessage, useIntl } from "react-intl"
import { useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"

import api from "api/api"
import { COLORS } from "assets/styles/colors"
import Dropzone from "components/dropzone/Dropzone"
import Controller from "components/form/Controller"
import AbsoluteLoader from "components/loader"
import ConfirmationModal from "components/modal/ConfirmationModal"
import { useSnackbarPromiseHandler } from "hooks/useSnackbar"
import { defaultValues as initDefaultValues, inputForm, schema } from "screens/productCreate/ProductCreate.form"
import { RootState, useReduxDispatch } from "store"
import actions from "store/actions"
import { ProductForm } from "types/form.types"

const ProductCreate = () => {
  const intl = useIntl()
  const [modal, setModal] = useState(false)
  const dispatch = useReduxDispatch()
  const navigate = useNavigate()
  const product = useSelector((state: RootState) => state.products.product)
  const { image, id, ...rest } = product || {}
  const { reference, ...restDuplicate } = rest

  const [loadingCreate, onSubmit] = useSnackbarPromiseHandler(async (data: ProductForm) => {
    const { image, ...rest } = data
    const img = image && Array.isArray(image) ? image[0] : undefined
    await api.products.create(rest, img)
    dispatch(actions.products.refresh())

    navigate(intl.formatMessage({ id: "route.products" }))
  }, `snackbar.success.product.${id ? "edit" : "create"}`)

  const [loadingDelete, onDelete] = useSnackbarPromiseHandler(async () => {
    id && (await api.products.delete(id.toString()))
    dispatch(actions.products.refresh())
    navigate(intl.formatMessage({ id: "route.products" }))
  }, "snackbar.success.product.delete")

  const loading = loadingCreate || loadingDelete

  const defaultValues = { ...initDefaultValues, ...rest, id }

  const { control, reset, handleSubmit, setValue, watch } = useForm<ProductForm>({
    defaultValues,
    resolver: yupResolver(schema),
  })

  const unitPrice = watch("unitPrice", defaultValues.unitPrice)
  const soldBy = watch("soldBy", defaultValues.soldBy)

  useEffect(() => {
    reset(defaultValues)
  }, [product, reset])

  return (
    <Container>
      {loading && <AbsoluteLoader />}
      <ConfirmationModal
        body="modal.delete.product.body"
        title="modal.delete.title"
        open={modal}
        onClose={() => setModal(false)}
        onConfirm={onDelete}
      />
      {window.history.length > 2 && (
        <Box mt={2} ml={2}>
          <Button color="secondary" variant="outlined" onClick={() => navigate(-1)}>
            <FormattedMessage id="button.goBack" />
          </Button>
        </Box>
      )}
      <Typography variant="h2" p={2} pb={3} pt={1}>
        <FormattedMessage id={id ? "screen.product.edit" : "screen.product.create"} />
      </Typography>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container bgcolor={COLORS.GREY_BG} p={2} spacing={2}>
          <Grid item container spacing={2} xs={7} lg={7} height="fit-content">
            {inputForm.map((k) => (
              <Fragment key={k.key}>
                <Grid item xs={k.size ?? 6}>
                  <Controller control={control} field={k} />
                </Grid>

                {k.key === "unitPrice" && (
                  <Grid item xs={6} key={k.key}>
                    <TextField
                      type={"number"}
                      variant="outlined"
                      label={intl.formatMessage({ id: `form.product.totalPrice` })}
                      fullWidth
                      disabled={true}
                      value={(unitPrice ?? 0) * (soldBy ?? 0)}
                      sx={{ backgroundColor: "white" }}
                    />
                  </Grid>
                )}
              </Fragment>
            ))}
          </Grid>
          <Grid item xs={5}>
            <Dropzone
              onChange={(e) => {
                setValue("image", e)
              }}
              name="image"
              value={image}
            />
          </Grid>
          <Grid item container xs={12} justifyContent="flex-end">
            {id && (
              <Button
                sx={{ mx: 2 }}
                variant="contained"
                color="secondary"
                onClick={() => {
                  navigate(intl.formatMessage({ id: "route.product.create" }))
                  dispatch(actions.products.setProduct(restDuplicate))
                }}
              >
                <FormattedMessage id="button.product.duplicate" />
              </Button>
            )}
            <Button type="submit" variant="contained">
              <FormattedMessage id="button.validate" />
            </Button>
            {id && (
              <IconButton
                onClick={() => setModal(true)}
                size="large"
                edge="start"
                color="inherit"
                aria-label="menu"
                sx={{ ml: 2, fontSize: 28 }}
              >
                <DeleteIcon fontSize={"inherit"} />
              </IconButton>
            )}
          </Grid>
        </Grid>
      </form>
    </Container>
  )
}

export default ProductCreate
