import styled from "@emotion/styled"

import { COLORS } from "assets/styles/colors"

export const DropContainer = styled.div`
  border: 4px dashed ${COLORS.GREY_BG};
  margin-right: 6px;
  padding: 12px;
  background-color: white;
  width: 100%;
`
